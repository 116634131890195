import { startOfDay } from 'date-fns'
import React, { useState, useCallback, SyntheticEvent, useMemo, ChangeEvent } from 'react'
import { UseMutateFunction, useQueryClient } from 'react-query'
import { Id, Dict } from '@memberapp/models'
import './Modal.css'

export type ModalInputChangeEvent = (
  event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>
) => void

export type SetObjOverride<T> = React.Dispatch<React.SetStateAction<T>> | ((obj: T) => void)

export interface GenericAddEditModalRenderProps<T> {
  obj: T
  errors: Dict<string>
  handleChange: ModalInputChangeEvent
  setObj?: SetObjOverride<T>
}

export interface GenericAddEditModalProps<T extends Id> {
  item: T | null
  dflt: T
  queryKey: string
  title: string
  mutate: UseMutateFunction<T, unknown, T, unknown> | null
  handleClose: () => void
  validate: (x: T) => Dict<string>
  render: (obj: T, errors: Dict<string>, handleChange: ModalInputChangeEvent, setObj: SetObjOverride<T>) => JSX.Element
}

function GenericAddEditModal<T extends Id>({
  item,
  dflt,
  mutate,
  handleClose,
  queryKey,
  title,
  validate,
  render,
}: GenericAddEditModalProps<T>): JSX.Element {
  const queryClient = useQueryClient()
  const [obj, setObj] = useState<T>(item ? { ...item } : { ...dflt })
  let errors: Dict<string> = { obj: 'incomplete' }
  if (obj !== dflt) {
    errors = validate ? validate(obj) : {}
  }

  const disabled = Object.keys(errors).length > 0

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target || !setObj) {
        return
      }
      const { name, value, checked, type: inputType } = event?.target as HTMLInputElement
      let newVal: unknown = null
      switch (inputType) {
        case 'checkbox':
          newVal = checked
          break
        case 'date':
          newVal = startOfDay(new Date(value))
          break
        case 'number':
        case 'numeric':
          newVal = +value
          break
        default:
          newVal = value
      }
      const newObj: T = { ...obj, [name]: newVal }
      setObj(newObj)
    },
    [obj, setObj]
  )

  const handleSave = async (evt: SyntheticEvent) => {
    evt.preventDefault()
    if (!obj) {
      return
    }
    if (!mutate) {
      handleClose && handleClose()
      return
    }
    mutate(obj, {
      onSuccess: (data: T) => {
        queryClient.setQueryData([queryKey, { id: data._id }], data)
        queryClient.invalidateQueries()
        handleClose && handleClose()
      },
    })
  }

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            {title}
            <a href="#close" aria-label="Close" className="close" onClick={handleClose}></a>
          </h3>
        </header>
        <p>{render(obj, errors, handleChange, setObj)}</p>
        <footer>
          <a className="small" onClick={handleClose}>
            Cancel
          </a>
          <button onClick={handleSave} aria-disabled={disabled} disabled={disabled} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default GenericAddEditModal
