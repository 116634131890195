import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { UserType } from '../models'
import { getUsers, queryKey } from '../services/userSvc'

type BoatFilterProps = {
  value: string
  onChange: (u: UserType | null) => void
}

const BoatFilter: React.FC<BoatFilterProps> = ({ value, onChange }) => {
  const { data: unFilteredBoats } = useQuery(queryKey, () => getUsers({ includeDeleted: false }))

  const boats = useMemo<UserType[]>(
    () =>
      (unFilteredBoats || [])
        .filter((f) => f.boatName)
        .sort((a: UserType, b: UserType) => a.boatName.localeCompare(b.boatName)),
    [unFilteredBoats]
  )

  const boatNames = boats?.map((b) => b.boatName.trim())

  const handleSelect = useCallback(
    (evt: SyntheticEvent) => {
      if (evt?.target) {
        const { value } = evt.target as HTMLSelectElement
        const boat = boats?.find((f) => f._id === value)
        onChange(boat || null)
      }
    },
    [onChange, boats]
  )

  return (
    <select value={value} onChange={handleSelect}>
      <option value="">All Boats</option>
      {boats?.map((u: UserType) => {
        const hasMultiple = boatNames.filter((b) => b === u.boatName.trim()).length > 1
        return (
          <option key={u._id} value={u._id}>
            {`${u.boatName}${hasMultiple ? ` (${u.name})` : ''}`}
          </option>
        )
      })}
    </select>
  )
}

export default BoatFilter
