"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterBaitOrderItemsForWarehouse = exports.applyFavouriteBaitOrderToBaitOrder = void 0;
const types_1 = require("../types");
function applyFavouriteBaitOrderToBaitOrder(baitOrder, favBaitOrder) {
    if (!favBaitOrder) {
        return baitOrder;
    }
    const newBaitOrder = Object.assign(Object.assign(Object.assign({}, baitOrder), favBaitOrder), { name: '', type: types_1.BaitOrderType.Trip });
    newBaitOrder.collectionDate = (baitOrder === null || baitOrder === void 0 ? void 0 : baitOrder.collectionDate) || null;
    delete newBaitOrder._id;
    return newBaitOrder;
}
exports.applyFavouriteBaitOrderToBaitOrder = applyFavouriteBaitOrderToBaitOrder;
function filterBaitOrderItemsForWarehouse(items = [], baitList = [], warehouse = '') {
    if (!warehouse) {
        return [];
    }
    const allowedBaitIds = baitList.filter(f => !f.deletedAt && (!f.warehouse || f.warehouse === warehouse)).map(m => m._id).filter(Boolean);
    return items.filter(f => allowedBaitIds.includes(f._id));
}
exports.filterBaitOrderItemsForWarehouse = filterBaitOrderItemsForWarehouse;
