import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import './FileUpload.css'
import ImagePreview from './ImagePreview'

const FileUpload = ({ accept, maxFiles, onFilesAdded }) => {
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept,
    maxFiles,
    multiple: maxFiles > 1,
  })

  const previews = useMemo(() => {
    return files?.map((m) => ({ ...m, preview: URL.createObjectURL(m) })) || []
  }, [files])

  useEffect(() => {
    if (setFiles) {
      setFiles([...acceptedFiles])
    }
  }, [acceptedFiles, setFiles])

  useEffect(() => {
    if (!onFilesAdded) {
      return
    }
    onFilesAdded(files)
  }, [files, onFilesAdded])

  const removeFile = useCallback(
    (index) => {
      if (!setFiles) {
        return
      }
      const newFiles = [...files].filter((el, i) => i !== index)
      setFiles(newFiles)
    },
    [files, setFiles]
  )

  return (
    <section className="fileupload">
      {previews?.length ? (
        <aside>
          {previews.map((preview, index) => {
            return (
              <span key={preview.name}>
                <ImagePreview src={preview.preview} onRemove={() => removeFile(index)} showRemove={true} />
              </span>
            )
          })}
        </aside>
      ) : (
        <div {...getRootProps({ className: 'dropzone' })} onClick={open}>
          <input {...getInputProps()} />
          <p>
            Drag and drop {maxFiles > 1 ? 'some files' : 'a file'} here, or click to select{' '}
            {maxFiles > 1 ? 'files' : 'a file'}
          </p>
          <em>
            limit of {maxFiles} {maxFiles > 1 ? 'files' : 'file'}
          </em>
        </div>
      )}
    </section>
  )
}

export default FileUpload
