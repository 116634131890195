import React from 'react'
import { SortAlhpabeticalAscending, SortAlhpabeticalDescending } from './Icons'

type SortButtonProps = {
  sortAscending: boolean
  toggleSort: () => void
}

const SortButton: React.FC<SortButtonProps> = ({ sortAscending, toggleSort }) => {
  if (!toggleSort) {
    return null
  }
  if (sortAscending) {
    return (
      <a href="#" onClick={toggleSort}>
        <SortAlhpabeticalDescending />
      </a>
    )
  }
  return (
    <a href="#" onClick={toggleSort}>
      <SortAlhpabeticalAscending />
    </a>
  )
}

export default SortButton
