import React from 'react'

interface Props {
  width?: number | string
  height?: number | string
  fill?: string
}
export const Trash: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
      />
    </svg>
  )
}
export const TrashStrikeThru: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M2 5.27L3.28 4L5 5.72L5.28 6L6.28 7L8.28 9L16 16.72L18 18.72L20 20.72L18.73 22L17.27 20.54C16.93 20.83 16.5 21 16 21H8C6.9 21 6 20.1 6 19V9.27L2 5.27M8 19H15.73L8 11.27V19M18 7V16.18L16 14.18V9H10.82L8.82 7H18M15.5 4H19V6H7.82L5.82 4H8.5L9.5 3H14.5L15.5 4Z"
      />
    </svg>
  )
}
export const DownloadDocument: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z"
      />
    </svg>
  )
}
export const LockReset: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M12.63,2C18.16,2 22.64,6.5 22.64,12C22.64,17.5 18.16,22 12.63,22C9.12,22 6.05,20.18 4.26,17.43L5.84,16.18C7.25,18.47 9.76,20 12.64,20A8,8 0 0,0 20.64,12A8,8 0 0,0 12.64,4C8.56,4 5.2,7.06 4.71,11H7.47L3.73,14.73L0,11H2.69C3.19,5.95 7.45,2 12.63,2M15.59,10.24C16.09,10.25 16.5,10.65 16.5,11.16V15.77C16.5,16.27 16.09,16.69 15.58,16.69H10.05C9.54,16.69 9.13,16.27 9.13,15.77V11.16C9.13,10.65 9.54,10.25 10.04,10.24V9.23C10.04,7.7 11.29,6.46 12.81,6.46C14.34,6.46 15.59,7.7 15.59,9.23V10.24M12.81,7.86C12.06,7.86 11.44,8.47 11.44,9.23V10.24H14.19V9.23C14.19,8.47 13.57,7.86 12.81,7.86Z"
      />
    </svg>
  )
}
export const Pencil: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
      />
    </svg>
  )
}
export const SortAlhpabeticalAscending: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M19 17H22L18 21L14 17H17V3H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3M9 7H7V5H9Z"
      />
    </svg>
  )
}
export const SortAlhpabeticalDescending: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M19 7H22L18 3L14 7H17V21H19M11 13V15L7.67 19H11V21H5V19L8.33 15H5V13M9 3H7C5.9 3 5 3.9 5 5V11H7V9H9V11H11V5C11 3.9 10.11 3 9 3M9 7H7V5H9Z"
      />
    </svg>
  )
}
export const Send: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path fill={fill} d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
    </svg>
  )
}
export const NotesMagnifyingGlass: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        fill={fill}
        d="M19.31 18.9L22.39 22L21 23.39L17.88 20.32C17.19 20.75 16.37 21 15.5 21C13 21 11 19 11 16.5C11 14 13 12 15.5 12C18 12 20 14 20 16.5C20 17.38 19.75 18.21 19.31 18.9M15.5 19C16.88 19 18 17.88 18 16.5C18 15.12 16.88 14 15.5 14C14.12 14 13 15.12 13 16.5C13 17.88 14.12 19 15.5 19M21 4V6H3V4H21M3 16V14H9V16H3M3 11V9H21V11H18.97C17.96 10.37 16.77 10 15.5 10C14.23 10 13.04 10.37 12.03 11H3Z"
      />
    </svg>
  )
}

export const PasswordVisible: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
        fill={fill}
      />
    </svg>
  )
}

export const PasswordHidden: React.FC<Props> = ({ width = 24, height = 24, fill = 'inherit' }) => {
  return (
    <svg style={{ width, height }} viewBox="0 0 24 24">
      <path
        d="M11 4C13.76 4 16 6.24 16 9C16 9.65 15.87 10.26 15.64 10.83L18.56 13.75C20.07 12.49 21.26 10.86 21.99 9C20.26 4.61 15.99 1.5 10.99 1.5C9.59 1.5 8.25 1.75 7.01 2.2L9.17 4.36C9.74 4.13 10.35 4 11 4ZM1 1.27L3.28 3.55L3.74 4.01C2.08 5.3 0.78 7.02 0 9C1.73 13.39 6 16.5 11 16.5C12.55 16.5 14.03 16.2 15.38 15.66L15.8 16.08L18.73 19L20 17.73L2.27 0L1 1.27ZM6.53 6.8L8.08 8.35C8.03 8.56 8 8.78 8 9C8 10.66 9.34 12 11 12C11.22 12 11.44 11.97 11.65 11.92L13.2 13.47C12.53 13.8 11.79 14 11 14C8.24 14 6 11.76 6 9C6 8.21 6.2 7.47 6.53 6.8ZM10.84 6.02L13.99 9.17L14.01 9.01C14.01 7.35 12.67 6.01 11.01 6.01L10.84 6.02Z"
        fill={fill}
      />
    </svg>
  )
}
