import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Dict } from '@memberapp/models'
import { format } from 'date-fns'
import Progress from '../../components/Progress'
import useModal, { ShowModal } from '../../hooks/useModal'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'
import { EmptyNews, NewsType } from '../../models'
import { addNews, editNews, getNews, queryKey, deleteNews, enableNews } from '../../services/newsSvc'
import { NewsContainer } from '../../containers/NewsContainer'
import NewsModal from '../../components/modals/NewsModal'
import ImagePreview from '../../components/ImagePreview'
import { Pencil } from '../../components/Icons'
import useDeleteEnable from '../../hooks/useDeleteEnable'
import FilterBar from './FilterBar'
import { dfltState } from './FilterBar'

const qmProps: QMProps<NewsType> = {
  queryKey: queryKey,
  listFn: getNews,
  addFn: addNews,
  editFn: editNews,
}

const News: React.FC = () => {
  const {
    data,
    addMutate,
    editMutate,
    reset: queryReset,
    error: queryError,
    isLoading: queryLoading,
    setQueryParams,
  } = useQueryAndMutations<NewsType>(qmProps)
  const { showModal, showAdd, showEdit, itemToEdit, handleClose } = useModal<NewsType>({ ...EmptyNews })
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>(dfltState)

  const { ConfirmModals, DeleteEnableActions, isDeleteEnableLoading, deleteEnableError, deleteEnableReset } =
    useDeleteEnable<NewsType>({
      name: 'News',
      data,
      queryKey,
      filters: queryParams,
      deleteFunc: deleteNews,
      enableFunc: enableNews,
    })

  const isLoading = useMemo<boolean>(() => queryLoading || isDeleteEnableLoading, [queryLoading, isDeleteEnableLoading])
  const error = useMemo(() => queryError || deleteEnableError, [queryError, deleteEnableError])
  const reset = useCallback(() => {
    queryReset && queryReset()
    deleteEnableReset && deleteEnableReset()
  }, [queryReset, deleteEnableReset])

  useEffect(() => {
    if (!setQueryParams) {
      return
    }
    setQueryParams(queryParams)
  }, [queryParams, setQueryParams])

  const sortedData = useMemo<NewsType[]>(() => {
    if (!data?.length) {
      return []
    }
    return data.sort((a: NewsType, b: NewsType) => a.scheduledToSendAt?.getTime() - b.scheduledToSendAt?.getTime())
  }, [data])

  return (
    <div>
      <hgroup>
        <h2>News</h2>
        <h3>
          <a href="#" className="outline" onClick={showAdd}>
            Add New
          </a>
        </h3>
      </hgroup>

      <FilterBar filters={queryParams} setQueryParams={setViewQueryParams} />

      {/* api state */}
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>Type</th>
            <th>Title&nbsp;</th>
            <th>Body</th>
            {queryParams?.type !== 'Message' ? <th>Image</th> : []}
            <th>Scheduled For</th>
            <th>Sent At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((obj: NewsType) => (
            <tr key={obj._id}>
              <td>{obj.type}</td>
              <td>{obj.title}</td>
              <td>{obj.body}</td>
              {queryParams?.type !== 'Message' ? (
                <td>
                  {obj?.eventSummary?.imageUri ? (
                    <ImagePreview src={obj.eventSummary.imageUri} onRemove={() => {}} />
                  ) : (
                    []
                  )}
                </td>
              ) : (
                []
              )}
              <td>{obj.scheduledToSendAt ? format(new Date(obj.scheduledToSendAt), 'd MMM yyyy HH:mm') : ''}</td>
              <td>{obj.sentAt ? format(new Date(obj.sentAt), 'd MMM yyyy HH:mm') : ''}</td>
              <td>
                <a href="#" className="outline" onClick={() => showEdit(obj)}>
                  <Pencil />
                </a>
                {DeleteEnableActions(obj)}
              </td>
            </tr>
          ))}
          {!sortedData?.length ? (
            <tr>
              <td colSpan={queryParams?.type !== 'Message' ? 7 : 6}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>

      {showModal !== ShowModal.None ? (
        <NewsContainer.Provider
          initialState={{
            obj: itemToEdit,
            mutate: showModal === ShowModal.Add ? addMutate : editMutate,
            modalType: showModal,
            handleClose,
          }}>
          <NewsModal />
        </NewsContainer.Provider>
      ) : (
        []
      )}
      {ConfirmModals()}
    </div>
  )
}

export default News
