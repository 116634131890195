import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus, InteractionRequiredAuthError, AccountInfo } from '@azure/msal-browser'
import { createContainer } from 'unstated-next'
import { Dict } from '@memberapp/models'
import { loginRequest } from '../authConfig'
import { callMsGraph } from '../utils/MsGraphApiCall'
import { ROLE_PREFIX } from '../config'
import { RequestMethod, untypedRequest } from '../services/httpSvc'

// not a real enum because needs a computed value
export const Roles = {
  Admin: `${ROLE_PREFIX}.Administrator`,
  DepotManager: `${ROLE_PREFIX}.DepotManager`,
}

function useAuth() {
  const { instance, inProgress } = useMsal()
  const [graphData, setGraphData] = useState<unknown>()
  const [account, setAccount] = useState<AccountInfo | null>(null)

  useEffect(() => {
    const checkAccount = async () => {
      if (!graphData && inProgress === InteractionStatus.None) {
        try {
          const response = await callMsGraph()
          setGraphData(response)
          setAccount(instance.getActiveAccount())
        } catch (e: unknown) {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            })
          }
        }
      }
    }
    checkAccount()
  }, [inProgress, graphData, instance])

  const roles = useMemo<string[]>(() => {
    const claims = (account?.idTokenClaims ? { ...account.idTokenClaims } : {}) as Dict<unknown>
    return (claims['roles'] as string[]) || []
  }, [account])

  const logout = useCallback(() => {
    if (instance && account) {
      const logoutRequest = {
        account,
        postLogoutRedirectUri: window.location.origin,
      }
      instance.logoutRedirect(logoutRequest)
    }
  }, [instance, account])

  useEffect(() => {
    const fetchApiAccessToken = async () => {
      const msAccessTokenKey = Object.keys(sessionStorage).find((f) => f.indexOf('login.windows.net-accesstoken') > -1)
      if (msAccessTokenKey) {
        const accessToken = JSON.parse(sessionStorage.getItem(msAccessTokenKey) || '{secret: ""}').secret
        try {
          const { token } = await untypedRequest(RequestMethod.POST, 'token', null, accessToken)
            .then((res) => res.json())
            .then((res: { token: string }) => res)

          sessionStorage.setItem('API_ACCESS_JWT', token)
        } catch (err) {
          instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount() as AccountInfo,
          })
        }
      }
    }

    if (account) {
      fetchApiAccessToken()
    }
  }, [account, instance])

  return {
    instance,
    graphData,
    account,
    roles,
    logout,
  }
}

export const AuthContainer = createContainer(useAuth)
