"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsDefaultKeysEnum = exports.NotificationTopics = exports.NewsType = exports.TripTypeEnum = exports.BaitOrderDelivery = exports.BaitOrderType = exports.MemberCategory = void 0;
var MemberCategory;
(function (MemberCategory) {
    MemberCategory["NonSkipper"] = "NonSkipper";
    MemberCategory["Skipper"] = "Skipper";
})(MemberCategory = exports.MemberCategory || (exports.MemberCategory = {}));
var BaitOrderType;
(function (BaitOrderType) {
    BaitOrderType["Favourite"] = "Favourite";
    BaitOrderType["Trip"] = "Trip";
    BaitOrderType["Scheduled"] = "Scheduled";
})(BaitOrderType = exports.BaitOrderType || (exports.BaitOrderType = {}));
var BaitOrderDelivery;
(function (BaitOrderDelivery) {
    BaitOrderDelivery["WhenIDeliver"] = "Yes, when I deliver";
    BaitOrderDelivery["Tonight"] = "Yes, tonight";
    BaitOrderDelivery["Scheduled"] = "Yes, select date";
    BaitOrderDelivery["Nope"] = "Nope";
})(BaitOrderDelivery = exports.BaitOrderDelivery || (exports.BaitOrderDelivery = {}));
var TripTypeEnum;
(function (TripTypeEnum) {
    TripTypeEnum["Trip"] = "Trip";
    TripTypeEnum["Favourite"] = "Favourite";
    TripTypeEnum["NotGoing"] = "NotGoing";
    TripTypeEnum["HangingOut"] = "HangingOut";
})(TripTypeEnum = exports.TripTypeEnum || (exports.TripTypeEnum = {}));
var NewsType;
(function (NewsType) {
    NewsType["Message"] = "Message";
    NewsType["Event"] = "Event";
})(NewsType = exports.NewsType || (exports.NewsType = {}));
var NotificationTopics;
(function (NotificationTopics) {
    NotificationTopics["BeachPrice"] = "beach_price";
    NotificationTopics["NewsMessage"] = "message";
    NotificationTopics["NewsEvent"] = "event";
})(NotificationTopics = exports.NotificationTopics || (exports.NotificationTopics = {}));
var SettingsDefaultKeysEnum;
(function (SettingsDefaultKeysEnum) {
    SettingsDefaultKeysEnum["DEFAULT_BEACH_PRICES_NOTIFICATION_24H_TIME"] = "DEFAULT_BEACH_PRICES_NOTIFICATION_24H_TIME";
    SettingsDefaultKeysEnum["BEACH_PRICE_EMAIL_NOTIFICATION_ENABLED"] = "BEACH_PRICE_EMAIL_NOTIFICATION_ENABLED";
    SettingsDefaultKeysEnum["TRIP_UPDATE_EMAIL_NOTIFICATION_24H_TIME"] = "TRIP_UPDATE_EMAIL_NOTIFICATION_24H_TIME";
    SettingsDefaultKeysEnum["TRIP_UPDATE_EMAIL_NOTIFICATION_RECIPIENT"] = "TRIP_UPDATE_EMAIL_NOTIFICATION_RECIPIENT";
    SettingsDefaultKeysEnum["TRIP_PREDICTION_WEIGHTING_PERCENTAGE_LAST_3_TRIPS"] = "TRIP_PREDICTION_WEIGHTING_PERCENTAGE_LAST_3_TRIPS";
    SettingsDefaultKeysEnum["DEFAULT_KGS_PER_BASKET"] = "DEFAULT_KGS_PER_BASKET";
})(SettingsDefaultKeysEnum = exports.SettingsDefaultKeysEnum || (exports.SettingsDefaultKeysEnum = {}));
