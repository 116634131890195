import { useCallback, useContext, useMemo, useState } from 'react'
import { ModalContext } from '../contexts/ModalContext'

export enum ShowModal {
  Add = 'Add',
  Edit = 'Edit',
  None = 'None',
}

function useModal<T>(dflt?: T) {
  const { openModal: open, closeModal: close } = useContext(ModalContext)
  const [showModal, setShowModal] = useState<ShowModal>(ShowModal.None)
  const [itemToEdit, setItemToEdit] = useState<T | null>(null)

  const closeModal = useCallback(() => {
    if (!close || !setShowModal || !setItemToEdit) {
      return
    }
    setShowModal(ShowModal.None)
    setItemToEdit(null)
    close()
  }, [close, setItemToEdit, setShowModal])

  const showAdd = useCallback(() => {
    if (!setItemToEdit || !setShowModal || !open) {
      return
    }
    setItemToEdit(dflt || null)
    setShowModal(ShowModal.Add)
    open()
  }, [open, setItemToEdit, setShowModal, dflt])

  const showEdit = useCallback(
    (item: T) => {
      if (!setItemToEdit || !setShowModal || !open) {
        return
      }
      setItemToEdit(item)
      setShowModal(ShowModal.Edit)
      open()
    },
    [open, setItemToEdit, setShowModal]
  )

  const handleClose = useCallback(() => {
    if (!closeModal) {
      return
    }
    closeModal()
  }, [closeModal])

  const output = { handleClose, showModal, itemToEdit, showAdd, showEdit }

  return output
}
export default useModal
