"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyFavouriteTripToTrip = void 0;
const date_fns_1 = require("date-fns");
const types_1 = require("../types");
function applyFavouriteTripToTrip(trip, favTrip) {
    if (!favTrip) {
        return trip;
    }
    const newTrip = Object.assign(Object.assign(Object.assign({}, trip), favTrip), { name: '', type: types_1.TripTypeEnum.Trip });
    newTrip.tripDate = trip.tripDate;
    newTrip.offloadDate =
        (0, date_fns_1.isAfter)(trip.tripDate, trip === null || trip === void 0 ? void 0 : trip.offloadDate) ? trip.tripDate : trip === null || trip === void 0 ? void 0 : trip.offloadDate;
    if (trip._id) {
        newTrip._id = trip._id;
    }
    else {
        delete newTrip._id;
    }
    if (favTrip.baitOrder) {
        newTrip.baitOrder = Object.assign({}, favTrip.baitOrder);
        newTrip.baitOrder.user = trip.user;
        newTrip.baitOrder.collectionDate = trip.tripDate;
    }
    return newTrip;
}
exports.applyFavouriteTripToTrip = applyFavouriteTripToTrip;
