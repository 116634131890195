import { Dict, SettingsDefaultKeysEnum, predictedBasketsCalculation } from '@memberapp/models'
import React, { PropsWithChildren, SyntheticEvent, useState } from 'react'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { NotesMagnifyingGlass, Pencil } from '../../components/Icons'
import useUpdateTripBaskets from '../../hooks/useUpdateTripBaskets'
import { TripType } from '../../models'
import { getSettings, queryKey } from '../../services/settingsSvc'

type TripRowProps = {
  trip: TripType
  showEdit: (obj: TripType) => void
}

const TripRow: React.FC<PropsWithChildren<TripRowProps>> = ({ trip, showEdit, children }) => {
  const { data: settings } = useQuery(queryKey, getSettings)

  const [actualBaskets, setActualBaskets] = useState<string | number>(
    trip.actualBaskets !== null && trip.actualBaskets !== undefined ? trip.actualBaskets : ''
  )

  const {
    mutation: { mutate, isLoading, error },
    onSuccess,
  } = useUpdateTripBaskets(trip?._id)

  const handleChange = (evt: SyntheticEvent) => {
    if (evt?.target) {
      setActualBaskets((evt.target as HTMLInputElement).value)
    }
  }

  const isActualBasketsChanged = trip?.actualBaskets !== actualBaskets

  const errs: Dict<string> = {}
  const parsed = parseInt(actualBaskets.toString())
  if (actualBaskets !== '') {
    if (isNaN(parsed)) {
      errs['isNaN'] = 'not a number'
    } else if (parsed < 0) {
      errs['tooLow'] = 'must be >= 0'
    }
  }
  const errors = Object.keys(errs).length ? errs : null

  let invalid: string | undefined = 'false'
  if (!isActualBasketsChanged || actualBaskets === '' || isLoading) {
    invalid = undefined
  } else if (error || (errors && Object.keys(errors).length)) {
    invalid = 'true'
  }

  const handleBlur = () => {
    if (isActualBasketsChanged && invalid === 'false') {
      const val = parseInt(actualBaskets.toString())
      const baskets = isNaN(val) ? null : val
      mutate(baskets, { onSuccess })
    }
  }

  const percentageList =
    settings
      ?.find((v) => v._id === SettingsDefaultKeysEnum.TRIP_PREDICTION_WEIGHTING_PERCENTAGE_LAST_3_TRIPS)
      ?.value?.split(',') || []
  const calculatePredictedBaskets = predictedBasketsCalculation({ percentageList, trip })?.displayValue

  return (
    <tr key={trip?._id}>
      <td>{trip.user.boatName}</td>
      <td>{trip.type}</td>
      <td>
        {trip.offloadLocation
          ? [trip.offloadLocation.name, trip.offloadLocation.grading].filter(Boolean).join(' - ')
          : ''}
      </td>
      <td>{trip.offloadTime}</td>
      <td>
        {trip?.estBasketLastModifiedAt ? format(new Date(trip?.estBasketLastModifiedAt), 'dd MMM yyy hh:mm aaa') : '-'}
      </td>
      <td className="baskets">{trip.estimatedBaskets}</td>
      <td>{calculatePredictedBaskets}</td>
      <td className={`baskets ${invalid === 'true' ? 'error' : ''}`}>
        <input
          type="number"
          value={!isNaN(parseInt(`${actualBaskets}`)) ? `${actualBaskets}` : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          min={0}
        />
      </td>
      <td>{trip.createdBy}</td>
      <td>
        <a href="#" className="outline" onClick={() => showEdit(trip)}>
          <Pencil />
        </a>
        {children}
        {trip.comments ? (
          <a href="#" className="outline" data-tooltip={trip.comments} data-placement="left">
            <NotesMagnifyingGlass />
          </a>
        ) : (
          []
        )}
      </td>
    </tr>
  )
}

export default TripRow
