import { Dict, Id, User } from '@memberapp/models'
import { addDays, startOfToday } from 'date-fns'
import { UserType } from '../models'
import { buildQueryParams, Get, RequestMethod, untypedRequest, Upload } from './httpSvc'

export const queryKey = 'users'
export const usersOnBreakQueryKey = 'users/onBreak'
const url = 'users'
const usersOnBreakUrl = 'users/onBreak'
const uploadUrl = 'users/upload'

export const getUsers = async (query?: Dict<unknown>) => Get<UserType[]>(buildQueryParams(url, query))

export const getUsersOnBreak = async () => Get<UserType[]>(usersOnBreakUrl)

export const postUserCsv = async (user: FormData) => Upload<FormData>(uploadUrl, user)

export const deleteUser = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)

export const enableUser = async (id: string) => untypedRequest(RequestMethod.PUT, `${url}/${id}`, { deletedAt: null })

export const updateUserNotifications = async (u: User & Id): Promise<User & Id> =>
  untypedRequest(RequestMethod.PUT, `${url}/${u._id || ''}`, { notificationSettings: u.notificationSettings })
    .then((res) => res.json())
    .then((r) => r as User & Id)

export const passwordReset = async (id: string) =>
  untypedRequest(RequestMethod.POST, `${url}/passwordReset/${id}`, { deletedAt: null })

export const userNoLoginReport = async (val = '') => {
  let query = `${url}/reports/login`
  let date: Date | null = null
  switch (val) {
    case 'Last Week':
      date = addDays(startOfToday(), -7)
      break
    case 'Last Month':
      date = addDays(startOfToday(), -30)
      break
    case 'Last Year':
      date = addDays(startOfToday(), -365)
      break
    default:
      date = null
      break
  }
  if (date) {
    query = `${query}?notLoggedInSince=${date.toISOString()}`
  }
  return untypedRequest(RequestMethod.GET, query)
}

export const usersNotSubscribed = async (topic: string) => {
  let query = `${url}/reports/subscriptions`
  if (topic) {
    query = `${query}?topic=${topic}`
  }
  return untypedRequest(RequestMethod.GET, query)
}
