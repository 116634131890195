import React, { PropsWithChildren, SyntheticEvent, useCallback } from 'react'

type FilterProps = {
  value: string
  onChange: (u: string) => void
}
const BasicSelect: React.FC<PropsWithChildren<FilterProps>> = ({ value, onChange, children }) => {
  const handleChange = useCallback(
    (evt: SyntheticEvent) => {
      if (evt?.target) {
        const { value } = evt.target as HTMLSelectElement
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <select value={value} onChange={handleChange}>
      {children}
    </select>
  )
}

export default BasicSelect
