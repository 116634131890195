import React, { useMemo, useRef, useState } from 'react'
import { QueryClient, useMutation } from 'react-query'
import Progress from '../../components/Progress'
import { postUserCsv, queryKey } from '../../services/userSvc'

const queryClient = new QueryClient()

const UserUpload: React.FC = () => {
  const [csvData, setCsvData] = useState<null | File>(null)
  const [showUploadInfo, setShowUploadInfo] = useState(false)
  const input = useRef<HTMLInputElement | null>(null)
  const {
    mutate: uploadUserCsv,
    isLoading,
    error,
    isSuccess,
  } = useMutation(postUserCsv, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    },
    onMutate: () => {
      setShowUploadInfo(true)
      setCsvData(null)
      if (input.current) {
        input.current.value = ''
      }
    },
  })

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return
    }
    setCsvData(e.target.files[0])
  }

  const uploadUsers = () => {
    if (csvData) {
      const formData = new FormData()
      formData.append('file', csvData)
      uploadUserCsv(formData)
    }
  }

  const errorMessages = useMemo(() => {
    if (error && (error as Error)?.message) {
      return [JSON.parse((error as Error)?.message)?.error as string[]].flat()
    }
  }, [error])

  return (
    <div>
      <hgroup>
        <h2>Users Upload</h2>
        <h3>&nbsp;</h3>
        <div>
          {isLoading ? <Progress /> : []}
          <input
            onClick={() => setShowUploadInfo(false)}
            style={{ width: '15rem' }}
            ref={(ref) => (input.current = ref)}
            accept=".csv"
            type="file"
            onChange={changeHandler}
          />
        </div>
        {csvData ? (
          <div>
            <button disabled={isLoading} style={{ width: '10rem' }} onClick={uploadUsers}>
              Upload
            </button>
          </div>
        ) : (
          []
        )}
        {showUploadInfo ? (
          <>
            {(error as Error)?.message ? 'Upload failed' : ''}
            <div>{errorMessages?.length ? errorMessages.map((err, index) => <div key={index}>{err}</div>) : []}</div>
            <div>{isSuccess ? 'Uploaded successfully' : []}</div>
          </>
        ) : (
          []
        )}
      </hgroup>
    </div>
  )
}

export default UserUpload
