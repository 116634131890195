import { Dict, NewsType as NewsTypeEnum } from '@memberapp/models'
import { startOfToday } from 'date-fns'
import React, { useCallback } from 'react'
import BasicSelect from '../../components/BasicSelect'
import DateFilter from '../../components/DateFilter'

type Props = {
  setQueryParams: (query: Dict<unknown>) => void | React.Dispatch<React.SetStateAction<Dict<unknown>>>
  filters: Dict<unknown>
}

export const dfltState: Dict<unknown> = {
  type: '',
  scheduledFor: startOfToday(),
  sentAt: null,
}

const FilterBar: React.FC<Props> = ({ filters, setQueryParams }) => {
  const onChange = useCallback(
    (e: string | Date | null, name: string) => {
      let val: string | Date | null = null
      switch (name) {
        case 'scheduledFor':
          val = e ? (e as Date) : null
          break
        case 'sentAt':
          val = e ? (e as Date) : null
          break
        default:
          val = e ? (e as string) : ''
          break
      }
      setQueryParams({ ...filters, [name]: val })
    },
    [setQueryParams, filters]
  )

  const clearQueryParams = useCallback(() => {
    setQueryParams(dfltState)
  }, [setQueryParams])

  return (
    <div className="filter-row">
      <label>Type</label>
      <BasicSelect value={filters.type as string} onChange={(e: string) => onChange(e, 'type')}>
        <option value="">All Types</option>
        <option value={`${NewsTypeEnum.Event}`}>Events</option>
        <option value={`${NewsTypeEnum.Message}`}>Messages</option>
      </BasicSelect>
      <label>Scheduled For:</label>
      <DateFilter value={filters.scheduledFor as Date} onChange={(e: Date | null) => onChange(e, 'scheduledFor')} />
      <label>Sent At:</label>
      <DateFilter value={filters.sentAt as Date} onChange={(e: Date | null) => onChange(e, 'sentAt')} />
      <a href="#" onClick={clearQueryParams}>
        Clear filters
      </a>
    </div>
  )
}

export default FilterBar
