import { SyntheticEvent, useEffect, useState } from 'react'
import './TimePicker.css'

interface Props {
  name: string
  initialValue?: string
  handleChange: (name: string, val: string) => void
}

const hoursList = Array(12)
  .fill(0)
  .map((_, i) => {
    return i < 9 ? '0' + (i + 1) : `${i + 1}`
  })
const minList = ['00', '15', '30', '45']
const meridiemList = ['AM', 'PM']

const initializeTime = (type: 'hour' | 'minutes' | 'meridiem', initialValue?: string) => {
  if (!initialValue) {
    return ''
  }
  const [hour, minStr] = initialValue.split(':')
  const [minutes, meridiem] = minStr.split(' ')
  const timeVal = { hour, minutes, meridiem }
  return timeVal[type]
}

export const TimePicker: React.FC<Props> = ({ name, handleChange, initialValue }) => {
  const [hour, setHour] = useState(() => initializeTime('hour', initialValue))
  const [minute, setMinute] = useState(() => initializeTime('minutes', initialValue))
  const [meridiem, setMeridiem] = useState(() => initializeTime('meridiem', initialValue))

  useEffect(() => {
    handleChange(name, `${hour}:${minute} ${meridiem}`)
  }, [handleChange, name, hour, minute, meridiem])

  const handleTime = (setValue: (value: string) => void) => (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement
    setValue(value)
  }

  return (
    <span className="container">
      <select name={`${name}Hour`} value={hour} onChange={handleTime(setHour)}>
        {hoursList.map((h, i) => (
          <option key={i} value={h}>
            {h}
          </option>
        ))}
      </select>
      <span className="divider">:</span>
      <select name={`${name}Minute`} value={minute} onChange={handleTime(setMinute)}>
        {minList.map((min, i) => (
          <option key={i} value={min}>
            {min}
          </option>
        ))}
      </select>
      <span className="divider">:</span>
      <select name={`${name}Meridiem`} value={meridiem} onChange={handleTime(setMeridiem)}>
        {meridiemList.map((m, i) => (
          <option key={i} value={m}>
            {m}
          </option>
        ))}
      </select>
    </span>
  )
}

export default TimePicker
