import { useMutation, useQueryClient } from 'react-query'
import { TripType } from '../models'
import { updateActualBaskets, queryKey } from '../services/tripSvc'

const useUpdateTripBaskets = (id = '') => {
  const queryClient = useQueryClient()

  const mutation = useMutation((baskets: number | null) => updateActualBaskets(id, baskets))

  const onSuccess = (data: TripType) => {
    const oldData = queryClient.getQueryData<TripType>([queryKey, id])
    if (oldData) {
      const newData: TripType = { ...oldData, actualBaskets: data.actualBaskets }
      queryClient.setQueryData([queryKey, id], newData)
    }
    queryClient.invalidateQueries(queryKey)
  }

  return { mutation, onSuccess }
}

export default useUpdateTripBaskets
