import React, { useCallback } from 'react'
import { NewsContainer } from '../../containers/NewsContainer'
import { NewsType } from '../../models'
import LabelWithError from '../LabelWithError'
import MultiStringInput from '../MultiStringInput'

const NewsFormMessageSummary: React.FC = () => {
  const { news, setNews, errors } = NewsContainer.useContainer()
  const onMessageSummaryChange = useCallback(
    (items: string[]) => {
      if (!setNews) {
        return
      }
      setNews({ ...news, messageSummary: items } as NewsType)
    },
    [setNews, news]
  )
  return (
    <>
      <LabelWithError {...{ name: 'messageSummary', errors }} className="label-top">
        Message Summary
      </LabelWithError>
      <MultiStringInput name="messageSummary" items={news?.messageSummary || []} onChange={onMessageSummaryChange} />
    </>
  )
}

export default NewsFormMessageSummary
