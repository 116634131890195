import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { LocationType } from '../models'
import { getLocations, queryKey } from '../services/locationsSvc'

type FilterProps = {
  value: string
  onChange: (u: LocationType | null) => void
  placeholderText?: string
  showCarrierBoats?: boolean
}

const LocationFilter: React.FC<FilterProps> = ({ value, onChange, placeholderText = '', showCarrierBoats = true }) => {
  const { data } = useQuery(queryKey, () => getLocations())

  const landLocations = useMemo<LocationType[]>(
    () =>
      (data || [])
        .filter((f) => !f.isCarrierBoat)
        .sort((a: LocationType, b: LocationType) => a.name.localeCompare(b.name)),
    [data]
  )
  const boatLocations = useMemo<LocationType[]>(
    () =>
      (data || [])
        .filter((f) => f.isCarrierBoat)
        .sort((a: LocationType, b: LocationType) => a.name.localeCompare(b.name)),
    [data]
  )

  const handleSelect = useCallback(
    (evt: SyntheticEvent) => {
      if (evt?.target) {
        const { value } = evt.target as HTMLSelectElement
        const location = data?.find((f) => f._id === value)
        onChange(location || null)
      }
    },
    [onChange, data]
  )

  return (
    <select value={value} onChange={handleSelect}>
      <option value="">{placeholderText || 'All Locations'}</option>
      {landLocations?.map((u: LocationType) => (
        <option key={u._id} value={u._id}>
          {u.name} - {u.grading?.toUpperCase()}
        </option>
      ))}
      {showCarrierBoats && boatLocations.length ? (
        <optgroup label="Carrier Boats">
          {boatLocations?.map((u: LocationType) => (
            <option key={u._id} value={u._id}>
              {u.name}
            </option>
          ))}
        </optgroup>
      ) : (
        []
      )}
    </select>
  )
}

export default LocationFilter
