import { RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'uploads'

type TokenType = { token: string }

export const generateSasToken = async (filename: string): Promise<TokenType> =>
  untypedRequest(RequestMethod.POST, `${queryKey}/${filename}`, null)
    .then((res) => res.json())
    .then((res) => res as TokenType)
