import { useMemo, useState } from 'react'
import Progress from '../../components/Progress'
import { AuthContainer, Roles } from '../../containers/AuthContainer'
import { getBaitOrderInfo, getBaitOrderSummary } from '../../services/baitOrderSvc'
import './Reports.css'
import { downloadFile } from './downloadFile'
import TripsForDate from './components/TripsForDate'
import BaitOrdersDateLocation from './components/BaitOrdersDateLocation'
import UsersNoLogin from './components/UsersNoLogin'
import UsersNotSubscribed from './components/UsersNotSubscribed'

const Reports = () => {
  const [isDownloading, setIsDownloading] = useState(false)
  const { roles } = AuthContainer.useContainer()

  const isAdmin = useMemo(() => {
    return roles?.length && roles.indexOf(Roles.Admin) > -1
  }, [roles])

  const downloadReport = async (fn: () => Promise<Response>) => {
    setIsDownloading(true)
    try {
      const res = await fn()
      await downloadFile(res)
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <div className="reports">
      {isDownloading ? <Progress /> : []}
      <h4>Trip Reports</h4>
      <TripsForDate downloadReport={downloadReport} />
      <h4>Bait Order Reports</h4>
      <BaitOrdersDateLocation downloadReport={downloadReport} label="Per Boat For Date" getFn={getBaitOrderInfo} />
      <BaitOrdersDateLocation downloadReport={downloadReport} label="Per Bait For Date" getFn={getBaitOrderSummary} />

      {isAdmin ? (
        <>
          <h4>User Reports</h4>
          <UsersNoLogin downloadReport={downloadReport} />
          <UsersNotSubscribed downloadReport={downloadReport} />
        </>
      ) : (
        []
      )}
    </div>
  )
}

export default Reports
