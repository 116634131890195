import { Dict } from '@memberapp/models'
import { NewsType } from '../models'
import { buildQueryParams, Get, Post, Put, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'news'
const url = 'news'

const mapNews = (obj: NewsType): NewsType => {
  const news = { ...obj } as NewsType
  news.date = new Date(news.date)
  if (news.scheduledToSendAt) {
    news.scheduledToSendAt = new Date(news.scheduledToSendAt)
  }
  if (news.sentAt) {
    news.sentAt = new Date(news.sentAt)
  }
  if (news.eventSummary?.time) {
    news.eventSummary.time = new Date(news.eventSummary.time)
  }
  return news
}

export const getNews = async (query?: Dict<unknown>) =>
  Get<NewsType[]>(buildQueryParams(url, query)).then((res: NewsType[]) => res.map(mapNews))

export const addNews = async (obj: NewsType) => Post<NewsType>(url, obj).then(mapNews)

export const editNews = async (obj: NewsType) => Put<NewsType>(`${url}/${obj._id}`, obj).then(mapNews)

export const deleteNews = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)
export const enableNews = async (id: string) => untypedRequest(RequestMethod.PUT, `${url}/${id}`, { deletedAt: null })
