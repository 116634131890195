import { Dict } from '@memberapp/models'
import { BASE_API_URL } from '../config'

export enum RequestMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

type RequestOptions = {
  method: RequestMethod
  headers: { [id: string]: string }
  body?: string | FormData
}

const apiBase = BASE_API_URL.startsWith('http') ? BASE_API_URL : `/${BASE_API_URL}`

export const buildQueryParams = (url: string, query?: Dict<unknown>) => {
  const params: string[] = []
  if (query) {
    Object.keys(query).forEach((key: string) => {
      if (query[key]) {
        const val = `${query[key]}`
        if (val.indexOf(',') < 0) {
          params.push(`${key}=${query[key]}`)
        } else {
          const chunks = val.split(',')
          chunks.forEach((c: string) => params.push(`${key}=${c}`))
        }
      }
    })
  }
  const queryParams = params.length ? params.join('&') : ''
  const qUrl = queryParams ? `${url}?${queryParams}` : url
  return qUrl
}

const getOptions = (
  method: RequestMethod,
  body: unknown | null | FormData,
  token: string | null = null,
  contentType: string | null
): RequestOptions => {
  let options: RequestOptions = {
    method,
    headers: {
      'Content-Type': contentType || 'application/json',
    },
  }
  const jwt = token || sessionStorage.getItem('API_ACCESS_JWT')
  if (jwt) {
    options.headers = { ...options.headers, Authorization: `Bearer ${jwt}` }
  }
  if (body) {
    options = { ...options, body: contentType !== 'multipart/form-data' ? JSON.stringify(body) : (body as FormData) }
  }
  return options
}

const genericRequest = async <T>(
  method: RequestMethod,
  endpoint: string,
  body: T | null = null,
  token: string | null = null,
  contentType: string | null = null
): Promise<T> => {
  return untypedRequest(method, endpoint, body, token, contentType).then(async (res) => {
    if (!res.ok) throw new Error(await res.text())
    return await res.json()
  })
}

export const untypedRequest = async (
  method: RequestMethod,
  endpoint: string,
  body: unknown | null = null,
  token: string | null = null,
  contentType: string | null = null
): Promise<Response> => {
  const uri = `${apiBase}/${endpoint}`
  const options = getOptions(method, body, token, contentType)
  if (contentType === 'multipart/form-data') {
    delete options.headers['Content-Type']
  }

  return fetch(uri, options)
}

export const Get = async <T>(endpoint: string) => genericRequest<T>(RequestMethod.GET, endpoint)
export const Post = async <T>(endpoint: string, body: T) => genericRequest<T>(RequestMethod.POST, endpoint, body)
export const Put = async <T>(endpoint: string, body: T) => genericRequest<T>(RequestMethod.PUT, endpoint, body)
export const Patch = async <T>(endpoint: string, body: T) => genericRequest<T>(RequestMethod.PATCH, endpoint, body)
export const Delete = async <T>(endpoint: string) => genericRequest<T>(RequestMethod.DELETE, endpoint)
export const Upload = async <T>(endpoint: string, body: T) =>
  genericRequest<T>(RequestMethod.POST, endpoint, body, null, 'multipart/form-data')
