import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryErrorResetBoundary } from 'react-query'
import { Id, Dict } from '@memberapp/models'

export interface QMProps<T extends Id> {
  queryKey: string
  listFn: (query?: Dict<unknown>) => Promise<T[]>
  addFn: (obj: T) => Promise<T>
  editFn: (obj: T & Id) => Promise<T>
  defaultArgs?: Dict<unknown>
}

function useQueryAndMutations<T extends Id>({ queryKey, listFn, addFn, editFn, defaultArgs }: QMProps<T>) {
  const [queryParams, setQueryParams] = useState<Dict<unknown>>(defaultArgs || {})
  const [timestamp, setTimestamp] = useState<string>('')

  const { reset: queryReset } = useQueryErrorResetBoundary()
  const {
    data,
    isLoading: isQueryLoading,
    error: queryError,
    refetch,
  } = useQuery([queryKey, timestamp], () => listFn(queryParams))

  const {
    isLoading: isAddLoading,
    error: addError,
    reset: addReset,
    mutate: addMutate,
  } = useMutation((obj: T) => addFn(obj), {})

  const {
    isLoading: isEditLoading,
    error: editError,
    reset: editReset,
    mutate: editMutate,
  } = useMutation((obj: T) => editFn(obj), {})

  const isLoading = useMemo(() => {
    return isQueryLoading || isAddLoading || isEditLoading
  }, [isQueryLoading, isAddLoading, isEditLoading])

  const error = useMemo(() => {
    return queryError || addError || editError
  }, [queryError, addError, editError])

  useEffect(() => {
    if (!setTimestamp) {
      return
    }
    setTimestamp(new Date().getTime().toString())
  }, [queryParams, setTimestamp])

  const reset = useCallback(() => {
    if (error) {
      queryReset && queryReset()
      addReset && addReset()
      editReset && editReset()
    }
  }, [error, queryReset, addReset, editReset])

  return {
    setQueryParams,
    data,
    addMutate,
    editMutate,
    isLoading,
    error,
    reset,
    refetch,
  }
}

export default useQueryAndMutations
