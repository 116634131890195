const extractFileName = (res: Response): string => {
  const disposition = res.headers.get('Content-Disposition')
  const filename = disposition?.replace(' ', '').split(';filename="')[1].replace('"', '')
  return filename as string
}

export const downloadFile = async (res: Response) => {
  const filename = extractFileName(res)
  const blob = await res.blob()
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove() //afterwards we remove the element again
  URL.revokeObjectURL(url)
}
