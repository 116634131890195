import React, { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Dict } from '@memberapp/models'
import { LocationType } from '../../models'
import GenericAddEditModal, { ModalInputChangeEvent } from '../../components/modals/GenericAddEditModal'
import LabelWithError from '../../components/LabelWithError'
import Progress from '../../components/Progress'
import useModal, { ShowModal } from '../../hooks/useModal'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'
import {
  addLocation,
  getLocations,
  editLocation,
  queryKey,
  deleteLocation,
  enableLocation,
} from '../../services/locationsSvc'
import useDeleteEnable from '../../hooks/useDeleteEnable'
import FilterActiveDeleted from '../../components/FilterActiveDeleted'
import { Pencil } from '../../components/Icons'
import SortButton from '../../components/SortButton'

const dflt: LocationType = {
  name: '',
  isCarrierBoat: false,
} as LocationType

function validate(x: LocationType): Dict<string> {
  const errs: Dict<string> = {}
  if (!x.name) {
    errs['name'] = 'required'
  }
  return errs
}

const qmProps: QMProps<LocationType> = {
  queryKey: 'locations',
  listFn: getLocations,
  addFn: addLocation,
  editFn: editLocation,
}

const Locations: React.FC = () => {
  const queryClient = useQueryClient()
  const {
    data,
    addMutate,
    editMutate,
    reset: queryReset,
    error: queryError,
    isLoading: isQueryLoading,
    setQueryParams,
  } = useQueryAndMutations<LocationType>(qmProps)
  const { showModal, showAdd, showEdit, itemToEdit, handleClose } = useModal<LocationType>()
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>({ includeDeleted: false })
  const [sortAscending, setSortAscending] = useState<boolean>(true)

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )
  const { ConfirmModals, DeleteEnableActions, isDeleteEnableLoading, deleteEnableError, deleteEnableReset } =
    useDeleteEnable<LocationType>({
      name: 'Location',
      data,
      queryKey,
      filters: queryParams,
      deleteFunc: deleteLocation,
      enableFunc: enableLocation,
    })

  const isLoading = useMemo(() => {
    return isQueryLoading || isDeleteEnableLoading
  }, [isQueryLoading, isDeleteEnableLoading])

  const error = useMemo(() => {
    return queryError || deleteEnableError
  }, [queryError, deleteEnableError])

  const reset = useCallback(() => {
    if (queryClient) {
      deleteEnableReset()
      queryReset()
      queryClient.resetQueries()
    }
  }, [queryReset, queryClient, deleteEnableReset])

  const sortedData = useMemo<LocationType[]>(() => {
    if (!data?.length) {
      return []
    }
    if (sortAscending) {
      return data.sort((a: LocationType, b: LocationType) => a.name.localeCompare(b.name))
    }
    return data.sort((a: LocationType, b: LocationType) => b.name.localeCompare(a.name))
  }, [data, sortAscending])

  const toggleSort = useCallback(() => {
    if (!setSortAscending) {
      return
    }
    setSortAscending(!sortAscending)
  }, [sortAscending, setSortAscending])

  return (
    <div>
      <hgroup>
        <h2>Locations</h2>
        <h3>
          <a href="#" className="outline" onClick={showAdd}>
            Add New
          </a>
        </h3>
      </hgroup>

      {/* api state */}
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>
              Name&nbsp;
              <SortButton {...{ sortAscending, toggleSort }} />
            </th>
            <th>Carrier Boat?</th>
            <th>Grading</th>
            <th>Depot Email(s)</th>
            <th>
              <FilterActiveDeleted filters={queryParams} setQueryParams={handleSetQueryParams} />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((obj: LocationType) => (
            <tr key={obj._id}>
              <td>{obj?.name}</td>
              <td>{obj?.isCarrierBoat ? 'YES' : ''}</td>
              <td>{obj?.grading ? obj.grading.toUpperCase() : ''}</td>
              <td> {obj?.depotEmails || ''}</td>
              <td>
                <a href="#" className="outline" onClick={() => showEdit(obj)}>
                  <Pencil />
                </a>
                {DeleteEnableActions(obj)}
              </td>
            </tr>
          ))}
          {!sortedData?.length ? (
            <tr>
              <td colSpan={3}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>

      {showModal !== ShowModal.None ? (
        <GenericAddEditModal<LocationType>
          title={showModal === ShowModal.Add ? 'Add Location' : 'Edit Location'}
          queryKey="bait"
          handleClose={handleClose}
          mutate={showModal === ShowModal.Add ? addMutate : editMutate}
          dflt={dflt}
          validate={validate}
          item={itemToEdit}
          render={(obj: LocationType, errors: { [id: string]: unknown }, handleChange: ModalInputChangeEvent) => (
            <>
              <LabelWithError {...{ name: 'name', errors }}>Name</LabelWithError>
              <input type="text" name="name" id="name" value={obj.name} required onChange={handleChange} />
              <LabelWithError {...{ name: 'isCarrierBoat', errors }}>Carrier Boat?</LabelWithError>
              <input
                type="checkbox"
                name="isCarrierBoat"
                id="isCarrierBoat"
                checked={obj.isCarrierBoat}
                required
                onChange={handleChange}
              />
              <LabelWithError {...{ name: 'grading', errors }}>Grading</LabelWithError>
              <select value={obj?.grading || ''} name="grading" id="grading" onChange={handleChange}>
                <option disabled value="">
                  please select...
                </option>
                <option value="north">NORTH</option>
                <option value="south">SOUTH</option>
              </select>
              <LabelWithError {...{ name: 'depotEmails', errors }}>Depot Email(s)</LabelWithError>
              <input type="email" name="depotEmails" value={obj.depotEmails} onChange={handleChange} />
            </>
          )}
        />
      ) : (
        []
      )}
      {ConfirmModals()}
    </div>
  )
}

export default Locations
