import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { BlockBlobClient } from '@azure/storage-blob'
import { Dict, NewsType as NewsTypeEnum, validateNewsItem } from '@memberapp/models'
import { FileWithPath } from 'react-dropzone'
import { UseMutateFunction, useQueryClient } from 'react-query'
import { createContainer } from 'unstated-next'
import { ShowModal } from '../hooks/useModal'
import { EmptyNews, NewsType } from '../models'

import { queryKey } from '../services/newsSvc'
import { generateSasToken } from '../services/uploadSvc'

interface NewsContainerType {
  obj: NewsType | null
  mutate: UseMutateFunction<NewsType, unknown, NewsType, unknown> | undefined
  modalType: ShowModal
  handleClose: () => void
}

const dfltState: NewsContainerType = {
  obj: EmptyNews,
  mutate: undefined,
  modalType: ShowModal.None,
  handleClose: () => {
    throw Error('handleClose is not implemented')
  },
}

const getImageUploadToken = async (filename: string): Promise<string> =>
  generateSasToken(filename).then((res) => res.token)

function useNews(initialState: NewsContainerType = dfltState) {
  const { obj, mutate, modalType: modalStateInput, handleClose } = initialState
  const queryClient = useQueryClient()
  const [news, setNews] = useState(obj)
  const [modalType] = useState<ShowModal>(modalStateInput)
  const [imageFile, setImageFile] = useState<FileWithPath | null>(null)

  const errors = useMemo<Dict<string>>(() => {
    return validateNewsItem(news)
  }, [news])

  useEffect(() => {
    console.error(errors)
  }, [errors])

  const saveDisabled = useMemo<boolean>(() => !!Object.keys(errors)?.length, [errors])

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target || !setNews) {
        return
      }
      const { name, value, checked, type: inputType } = event?.target as HTMLInputElement
      let newVal: unknown = null
      switch (inputType) {
        case 'checkbox':
          newVal = checked
          break

        case 'number':
        case 'numeric':
          newVal = +value
          break
        case 'datetime-local':
          if (value) {
            newVal = new Date(value)
          } else {
            newVal = new Date()
          }
          break
        default:
          newVal = value
      }
      const newObj = { ...news, [name]: newVal }
      setNews(newObj as NewsType)
    },
    [news, setNews]
  )

  const handleTypeChange = useCallback(
    (e: SyntheticEvent) => {
      if (!setNews) {
        return
      }
      if (!e?.target) {
        return
      }
      const { value } = e.target as HTMLSelectElement
      setNews({
        ...news,
        type: value === `${NewsTypeEnum.Message}` ? NewsTypeEnum.Message : NewsTypeEnum.Event,
      } as NewsType)
    },
    [news, setNews]
  )

  const handleSave = useCallback(async () => {
    if (!mutate) {
      console.error('mutate is null or undefined')
      return
    }
    if (!news) {
      console.error('news is undefined')
      return
    }
    if (!news?._id) {
      delete news._id
    }
    if (news.type === NewsTypeEnum.Event && news.eventSummary) {
      if (imageFile) {
        console.info('preparing to upload image to blob storage...')
        const ext = imageFile.name.split('.').pop()
        const imageKey = `${crypto.randomUUID()}.${ext}`
        const friendlyImageName = imageFile.name
        console.info('getting SAS token...')
        const uploadSasToken = await getImageUploadToken(imageKey)
        const blockBlobClient = new BlockBlobClient(uploadSasToken)
        const fileData = await imageFile.arrayBuffer()
        console.info('uploading image to blob storage...')
        await blockBlobClient.uploadData(fileData)
        news.eventSummary.imageFriendlyName = friendlyImageName
        news.eventSummary.imageKey = imageKey
      }
    }
    await mutate(news, {
      onSuccess: (data: NewsType) => {
        queryClient.setQueryData([queryKey, { id: data._id }], data)
        queryClient.invalidateQueries()
        handleClose && handleClose()
      },
    })
  }, [mutate, news, queryClient, imageFile, handleClose])

  return {
    news,
    setNews,
    handleChange,
    handleTypeChange,
    imageFile,
    setImageFile,
    errors,
    saveDisabled,
    modalType,
    handleSave,
    handleClose,
  }
}

export const NewsContainer = createContainer(useNews)
