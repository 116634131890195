import React, { useMemo } from 'react'
import { NewsType as NewsTypeEnum } from '@memberapp/models'
import { format, formatISO } from 'date-fns'
import LabelWithError from '../LabelWithError'
import './NewsForm.css'
import { NewsContainer } from '../../containers/NewsContainer'
import NewsFormMessageSummary from './NewsFormMessageSumary'
import NewsFormEventSummary from './NewsFormEventSummary'

const NewsForm: React.FC = () => {
  const { news: obj, errors, handleTypeChange, handleChange } = NewsContainer.useContainer()
  const minDate = useMemo(() => new Date(), [])

  return obj ? (
    <>
      <LabelWithError {...{ name: 'type', errors }}>Type</LabelWithError>
      <select value={`${obj.type}`} name="type" id="type" onChange={handleTypeChange}>
        <option value={`${NewsTypeEnum.Message}`}>{NewsTypeEnum.Message}</option>
        <option value={`${NewsTypeEnum.Event}`}>{NewsTypeEnum.Event}</option>
      </select>

      <LabelWithError {...{ name: 'scheduledToSendAt', errors }}>Scheduled to send at</LabelWithError>
      <input
        name="scheduledToSendAt"
        type="datetime-local"
        value={obj.scheduledToSendAt ? format(obj.scheduledToSendAt, 'yyyy-MM-dd\'T\'HH:mm') : ''}
        onChange={handleChange}
        min={formatISO(minDate)}
      />

      <LabelWithError {...{ name: 'title', errors }}>Title</LabelWithError>
      <input type="text" id="title" name="title" value={obj.title} onChange={handleChange} />

      <LabelWithError {...{ name: 'body', errors }} className="label-top">
        Body
      </LabelWithError>
      <textarea id="body" name="body" value={obj.body} onChange={handleChange} rows={4} />

      {obj.type === NewsTypeEnum.Message ? <NewsFormMessageSummary /> : <NewsFormEventSummary />}
    </>
  ) : null
}

export default NewsForm
