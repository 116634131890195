import React, { SyntheticEvent, useCallback, useState } from 'react'
import './Counter.css'

type Props = {
  count: number
  setCount: (x: number) => void
}

const Counter: React.FC<Props> = ({ count, setCount }) => {
  const [countState, setCountState] = useState<number | ''>(count)
  const [isUsingInput, setIsUsingInput] = useState<boolean>(false)
  const updateCount = useCallback(
    (x: number) => {
      let newCount = (count || 0) + x
      if (newCount < 0) {
        newCount = 0
      }
      setCount(newCount)
    },
    [count, setCount]
  )

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event?.target) {
        return
      }
      const { value } = event.target as HTMLInputElement
      if (value !== '') {
        setCountState(+value)
      } else {
        setCountState('')
      }
    },
    [setCountState]
  )

  const onFocusInput = () => {
    setCountState(count)
    setIsUsingInput(true)
  }
  const onBlurInput = () => {
    setCount(countState ? countState : 0)
    setIsUsingInput(false)
  }

  return (
    <span className="counter">
      <button onClick={() => updateCount(-1)}>-</button>
      <label>
        <input
          type="number"
          step="1"
          min="0"
          value={isUsingInput ? countState : count}
          onChange={handleChange}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
        />
      </label>
      <button onClick={() => updateCount(1)}>+</button>
    </span>
  )
}

export default Counter
