import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { TripTypeEnum, SettingsDefaultKeysEnum } from '@memberapp/models'
import { startOfToday, startOfTomorrow } from 'date-fns'
import { getSettings, queryKey } from '../../../services/settingsSvc'
import BasicSelect from '../../../components/BasicSelect'
import { DownloadDocument } from '../../../components/Icons'
import LocationFilter from '../../../components/LocationsFilter'
import { LocationType, UserType } from '../../../models'
import { getTripInfo } from '../../../services/tripSvc'
import DateRangeFilter from '../../../components/DateRangeFilter'
import BoatFilter from '../../../components/BoatFilter'

type Props = {
  downloadReport: (fn: () => Promise<Response>) => Promise<void>
}

const TripsForDate: React.FC<Props> = ({ downloadReport }) => {
  const { data: settings } = useQuery(queryKey, getSettings)

  const defaultKgsPerBasket = useMemo(() => {
    return +(settings?.find((v) => v._id === SettingsDefaultKeysEnum.DEFAULT_KGS_PER_BASKET)?.value || 0)
  }, [settings])

  const [tripStartDate, setTripStartDate] = useState<Date>(startOfToday())
  const [tripEndDate, setTripEndDate] = useState<Date>(startOfTomorrow())
  const [tripLocationId, setTripLocationId] = useState('')
  const [tripType, setTripType] = useState<string>('')
  const [kilosPerBasket, setKilosPerBasket] = useState<number>(0)
  const [boat, setBoat] = useState<string>('')

  useEffect(() => {
    setKilosPerBasket(defaultKgsPerBasket)
  }, [defaultKgsPerBasket])

  const downloadTrip = () => {
    downloadReport(() =>
      getTripInfo(
        tripStartDate || startOfToday(),
        tripEndDate || startOfTomorrow(),
        kilosPerBasket,
        tripLocationId,
        tripType,
        boat
      )
    )
  }

  const handleKilosChange = (event: SyntheticEvent) => {
    if (!event?.target) {
      return
    }
    const { value } = event.target as HTMLInputElement
    if (!value || isNaN(parseFloat(value))) {
      setKilosPerBasket(defaultKgsPerBasket)
    } else {
      setKilosPerBasket(parseFloat(value))
    }
  }

  const handleStartDateChange = (date: Date | null) => {
    setTripStartDate(date || startOfTomorrow())
  }

  const handleEndDateChange = (date: Date | null) => {
    setTripEndDate(date || startOfTomorrow())
  }

  const handleBoatChange = (boat: UserType | null) => {
    setBoat(boat?._id || '')
  }

  return (
    <>
      <DateRangeFilter
        start={tripStartDate}
        end={tripEndDate}
        onStartChange={handleStartDateChange}
        onEndChange={handleEndDateChange}
        noWrapper={true}
      />{' '}
      <label>Location</label>
      <LocationFilter value={tripLocationId} onChange={(u: LocationType | null) => setTripLocationId(u?._id || '')} />
      <label>Trip Type</label>
      <BasicSelect value={tripType} onChange={(u: string) => setTripType(u)}>
        <option value="">Any Type</option>
        <option value={`${TripTypeEnum.Trip}`}>{`${TripTypeEnum.Trip}`}</option>
        <option value={`${TripTypeEnum.NotGoing}`}>{`${TripTypeEnum.NotGoing}`}</option>
        <option value={`${TripTypeEnum.HangingOut}`}>{`${TripTypeEnum.HangingOut}`}</option>
        <option value={`${TripTypeEnum.Favourite}`}>{`${TripTypeEnum.Favourite}`}</option>
      </BasicSelect>
      <label>Boat</label>
      <BoatFilter value={boat} onChange={handleBoatChange} />
      <label htmlFor="kgperbasket">kg/basket</label>
      <input id="kgperbasket" type="number" value={kilosPerBasket} onChange={handleKilosChange} />
      <button onClick={downloadTrip}>
        <DownloadDocument />
        Trips For Date
      </button>
    </>
  )
}

export default TripsForDate
