import React, { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { validatePassword } from '@memberapp/models/src/validators'
import { PasswordHidden, PasswordVisible } from '../../components/Icons'
import { ENV } from '../../config'
import { resetPwd } from '../../services/resetPasswordSvc'

interface Props {
  resetPassword: (pwd: string, confirmPwd: string) => void
  apiError: { message?: string }
  isLoading: boolean
}

const ResetPasswordForm: React.FC<Props> = ({ resetPassword, apiError, isLoading }) => {
  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')
  const [isSecureText, setIsSecureText] = useState(false)

  const errors = useMemo(() => {
    const errors = validatePassword(pwd)
    if (errors.length) {
      return errors
    }
    if (pwd !== confirmPwd) {
      errors.push('Passwords are not matching')
      return errors
    }
    return []
  }, [pwd, confirmPwd])

  return (
    <>
      <label>Password</label>
      <div className="pwd-field">
        <input
          minLength={6}
          type={isSecureText ? 'text' : 'password'}
          name="password"
          onChange={(e) => setPwd(e.target.value)}
        />
        <div onClick={() => setIsSecureText((v) => !v)}>{isSecureText ? <PasswordVisible /> : <PasswordHidden />}</div>
      </div>
      <label>Confirm Password</label>
      <input type="password" name="confirm_password" onChange={(e) => setConfirmPwd(e.target.value)} />
      {pwd && errors
        ? errors.map((err) => {
            return (
              <p key={err} className="error-text">
                {err}
              </p>
            )
          })
        : []}
      {apiError ? <p className="error-text">{apiError?.message}</p> : []}
      <button
        aria-busy={isLoading}
        onClick={() => resetPassword(pwd, confirmPwd)}
        disabled={!pwd || !!errors.length || isLoading}>
        {!isLoading && 'Submit'}
      </button>
    </>
  )
}

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams()

  const { mutate, isSuccess, error: apiError, isLoading } = useMutation(resetPwd)

  const isMobile = useMemo(() => /iphone|ipod|android/.test(navigator.userAgent.toLowerCase()), [])

  const resetPassword = async (pwd: string, confirmPwd: string) => {
    const token = searchParams.get('token')
    if (pwd !== confirmPwd || !token) {
      return
    }
    try {
      mutate({ token, pwd })
    } catch (error) {
      console.error(error)
    }
  }

  const renderContent = () => {
    if (isSuccess) {
      return (
        <div>
          <p>Your password has been changed successfully!</p>
          {isMobile ? (
            <a
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
              href={ENV === 'PROD' ? 'gfcmobile://' : `gfcmobile${ENV}://`}>
              Click here to open the mobile app
            </a>
          ) : (
            []
          )}
        </div>
      )
    }
    return (
      <ResetPasswordForm
        resetPassword={resetPassword}
        apiError={apiError as { message?: string }}
        isLoading={isLoading}
      />
    )
  }

  return (
    <div>
      <h2>Reset Password</h2>
      {renderContent()}
    </div>
  )
}

export default ResetPassword
