import { Settings as SettingsModel } from '@memberapp/models'
import { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Pencil } from '../../components/Icons'
import SettingsModal from '../../components/modals/SettingsModal'
import Progress from '../../components/Progress'
import { getSettings, queryKey } from '../../services/settingsSvc'

const Settings = () => {
  const { data: settings, isLoading: queryLoading, error: queryError } = useQuery(queryKey, getSettings)
  const [settingToEdit, setSettingToEdit] = useState<SettingsModel | null>(null)
  const isLoading = useMemo(() => queryLoading, [queryLoading])
  const error = useMemo(() => queryError, [queryError])

  const reset = () => {}
  return (
    <div>
      <hgroup>
        <h2>Settings</h2>
        <h3>&nbsp;</h3>
      </hgroup>
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>Id</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {settings?.length ? (
            settings.map((m: SettingsModel) => (
              <tr key={m._id}>
                <td>{m._id}</td>
                <td>{m.value}</td>
                <td>
                  <a href="#" onClick={() => setSettingToEdit(m)}>
                    <Pencil />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No results</td>
            </tr>
          )}
        </tbody>
      </table>
      {settingToEdit ? <SettingsModal onClose={() => setSettingToEdit(null)} obj={settingToEdit} /> : []}
    </div>
  )
}

export default Settings
