import React, { PropsWithChildren } from 'react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from '../authConfig'

import Progress from './Progress'
import AuthErrorDisplay from './AuthErrorDisplay'

const AuthenticatedPage: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const authRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={AuthErrorDisplay}
      loadingComponent={Progress}>
      <>{children}</>
    </MsalAuthenticationTemplate>
  )
}

export default AuthenticatedPage
