import { Dict } from '@memberapp/models'
import { BaitType } from '../models'
import { buildQueryParams, Get, Post, Put, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'bait'
const url = 'bait'

export const getBait = async (query?: Dict<unknown>) => Get<BaitType[]>(buildQueryParams(url, query))

export const addBait = async (obj: BaitType) => Post<BaitType>(url, obj)

export const editBait = async (obj: BaitType) => Put<BaitType>(`${url}/${obj._id}`, obj)

export const deleteBait = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)

export const enableBait = async (id: string) => untypedRequest(RequestMethod.PUT, `${url}/${id}`, { deletedAt: null })
