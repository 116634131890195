import { compareAsc, compareDesc, parse } from 'date-fns'
import { Trip } from '@memberapp/models'

export function sortTripsByOffloadTime(trips: Trip[], isAsc: boolean) {
  const compFn = isAsc ? compareAsc : compareDesc

  return trips.sort((a, b) => {
    const parseTime = (timeStr: string) => {
      if (timeStr.includes('-')) {
        const timeParts = timeStr.split(' - ')
        return parse(timeParts[0], 'HH:mm', new Date())
      }
      return parse(timeStr, 'hh:mm a', new Date())
    }

    const timeA = parseTime(a.offloadTime)
    const timeB = parseTime(b.offloadTime)

    return compFn(timeA, timeB)
  })
}
