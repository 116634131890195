import React from 'react'
import { MsalAuthenticationResult } from '@azure/msal-react'

const AuthErrorDisplay: React.FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <div>
      {error?.errorCode ? error.errorCode : []}
      {error?.errorMessage ? error.errorMessage : []}
    </div>
  )
}

export default AuthErrorDisplay
