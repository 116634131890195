import React, { createContext, useCallback, useState } from 'react'

const setModalOpeningClasses = () => {
  const html = document.querySelector('html')
  html?.classList.add('modal-is-open')
  html?.classList.add('modal-is-opening')
}

const setModalClosingClasses = () => {
  const html = document.querySelector('html')
  html?.classList.remove('modal-is-opening')
  html?.classList.add('modal-is-closing')
  clearModalClasses()
}

const clearModalClasses = () => {
  const html = document.querySelector('html')
  setTimeout(() => {
    html?.classList.remove('modal-is-open')
    html?.classList.remove('modal-is-opening')
    html?.classList.remove('modal-is-closing')
  }, 100)
}

interface ModalContextType {
  openModal: () => void
  closeModal: () => void
}

const dflt: ModalContextType = {
  openModal: () => {},
  closeModal: () => {},
}

export const ModalContext = createContext<ModalContextType>(dflt)

type ProviderProps = React.PropsWithChildren<unknown>

const ModalProvider = ({ children }: ProviderProps) => {
  const [modalCount, setModalCount] = useState<number>(0)

  const openModal = useCallback(() => {
    if (!setModalCount) {
      return
    }
    setModalOpeningClasses()
    setModalCount((prevState) => prevState + 1)
  }, [setModalCount])

  const closeModal = useCallback(() => {
    if (!setModalCount) {
      return
    }
    const newModalCount = modalCount - 1 < 0 ? 0 : modalCount - 1
    if (newModalCount <= 0) {
      setModalClosingClasses()
      setTimeout(() => clearModalClasses(), 200)
    }
    setModalCount(newModalCount)
  }, [modalCount, setModalCount])

  return <ModalContext.Provider value={{ openModal, closeModal }}>{children}</ModalContext.Provider>
}

export default ModalProvider
