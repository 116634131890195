import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { addDays, addHours, format, formatISO, startOfToday } from 'date-fns'
import { FileWithPath } from 'react-dropzone'
import { NewsContainer } from '../../containers/NewsContainer'
import { ShowModal } from '../../hooks/useModal'
import { NewsType } from '../../models'
import FileUpload from '../FileUpload'
import ImagePreview from '../ImagePreview'
import LabelWithError from '../LabelWithError'
import MultiStringInput from '../MultiStringInput'

const NewsFormEventSummary: React.FC = () => {
  const { news, setNews, errors, setImageFile, modalType } = NewsContainer.useContainer()

  const eventSummary = useMemo(() => {
    if (!news?.eventSummary) {
      return {
        time: addHours(addDays(startOfToday(), 7), 12),
        rsvp: '',
        location: [],
        imageFriendlyName: '',
        imageKey: '',
        imageUri: '',
      }
    }
    return news.eventSummary
  }, [news?.eventSummary])
  const minDate = useMemo(() => new Date(), [])

  const updateEventSummary = useCallback(
    (name: string, value: string | string[] | Date | null) => {
      if (!setNews) {
        return
      }
      const summary = { ...eventSummary, [name]: value }
      setNews({ ...news, eventSummary: summary } as NewsType)
    },
    [news, eventSummary, setNews]
  )

  const onTimeSelected = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()
      if (!updateEventSummary || !event?.target) {
        return
      }
      const { value } = event.target as HTMLSelectElement

      updateEventSummary('time', new Date(value))
    },
    [updateEventSummary]
  )

  const handleRsvpChange = useCallback(
    (e: SyntheticEvent) => {
      if (!updateEventSummary || !e?.target) {
        return
      }
      const { value } = e.target as HTMLInputElement
      updateEventSummary('rsvp', value)
    },
    [updateEventSummary]
  )

  const onLocationChange = useCallback(
    (items: string[]) => {
      if (!updateEventSummary) {
        return
      }
      updateEventSummary('location', items)
    },
    [updateEventSummary]
  )

  const onImagesChanged = useCallback(
    (images: FileWithPath[]) => {
      if (!setImageFile) {
        return
      }
      setImageFile(images?.length ? images[0] : null)
    },
    [setImageFile]
  )

  const clearUploadedImage = useCallback(() => {
    if (!setNews) {
      return
    }
    if (!news) {
      return
    }
    setNews({
      ...news,
      eventSummary: { ...news.eventSummary, imageUri: '', imageKey: '', imageFriendlyName: '' },
    } as NewsType)
  }, [setNews, news])

  return eventSummary ? (
    <>
      <LabelWithError {...{ name: 'eventSummary_time', errors }}>Event Date/time</LabelWithError>
      <input
        name="eventSummary.time"
        type="datetime-local"
        onChange={onTimeSelected}
        value={eventSummary.time ? format(eventSummary.time, 'yyyy-MM-dd\'T\'HH:mm') : ''}
        min={formatISO(minDate)}
      />
      <LabelWithError {...{ name: 'eventSummary_rsvp', errors }}>RSVP</LabelWithError>
      <input
        type="text"
        name="eventSummary_rsvp"
        id="eventSummary_rsvp"
        value={eventSummary.rsvp}
        onChange={handleRsvpChange}
      />
      {errors['eventSummary_rsvp'] ? (
        <label className="error msg">
          RSVP should be an email address, a phone number (8 - 10 digits only) or a website url (full url starting with
          http:// or https://)
        </label>
      ) : (
        []
      )}
      <LabelWithError {...{ name: 'image', errors }} className="label-top">
        Image
      </LabelWithError>
      {modalType === ShowModal.Edit && eventSummary.imageUri ? (
        <span>
          <ImagePreview showRemove={true} onRemove={clearUploadedImage} src={eventSummary.imageUri} />
        </span>
      ) : (
        <FileUpload accept="image/png,image/jpeg" maxFiles={1} onFilesAdded={onImagesChanged} />
      )}
      <LabelWithError {...{ name: 'eventSummary_location', errors }} className="label-top">
        Location
      </LabelWithError>
      <MultiStringInput name="eventSummary_location" items={eventSummary.location || []} onChange={onLocationChange} />
    </>
  ) : null
}

export default NewsFormEventSummary
