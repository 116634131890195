import { Id, NotificationTopics, User } from '@memberapp/models'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

type UserType = User & Id

type Props = {
  handleClose: () => void
  handleSave: (u: UserType) => void
  user: UserType
}

const UserModal: React.FC<Props> = ({ handleClose, handleSave, user }) => {
  const [beachPrice, setBeachPrice] = useState<boolean>(false)
  const [events, setEvents] = useState<boolean>(false)
  const [messages, setMessages] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      setBeachPrice(!!user.notificationSettings.find((f) => f === `${NotificationTopics.BeachPrice}`))
      setEvents(!!user.notificationSettings.find((f) => f === `${NotificationTopics.NewsEvent}`))
      setMessages(!!user.notificationSettings.find((f) => f === `${NotificationTopics.NewsMessage}`))
    }
  }, [user, setBeachPrice, setEvents, setMessages])
  const editSettings = useMemo(() => {
    const tmp: string[] = []
    if (beachPrice) {
      tmp.push(NotificationTopics.BeachPrice)
    }
    if (events) {
      tmp.push(NotificationTopics.NewsEvent)
    }
    if (messages) {
      tmp.push(NotificationTopics.NewsMessage)
    }
    return tmp
  }, [beachPrice, events, messages])

  const cancel = useCallback(() => handleClose && handleClose(), [handleClose])
  const save = useCallback(async () => {
    if (!handleSave) {
      return
    }
    handleSave({ ...user, notificationSettings: editSettings })
  }, [handleSave, editSettings, user])

  const onChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event.target) {
        return
      }
      const { name, checked } = event.target as HTMLInputElement
      switch (name) {
        case `${NotificationTopics.BeachPrice}`:
          setBeachPrice(checked)
          break
        case `${NotificationTopics.NewsEvent}`:
          setEvents(checked)
          break
        case `${NotificationTopics.NewsMessage}`:
          setMessages(checked)
          break
        default:
          break
      }
    },
    [setBeachPrice, setEvents, setMessages]
  )

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            Edit {user?.name || 'User'} Notification Settings
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          <label htmlFor="beach_price">
            <input type="checkbox" name="beach_price" id="beach_price" checked={beachPrice} onChange={onChange} />
            Beach Prices
          </label>
          <br />
          <label htmlFor="event">
            <input type="checkbox" name="event" id="event" checked={events} onChange={onChange} />
            Events
          </label>
          <br />
          <label htmlFor="message">
            <input type="checkbox" name="message" id="message" checked={messages} onChange={onChange} />
            Messages
          </label>
          <br />
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default UserModal
