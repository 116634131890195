import React, { useEffect, useRef } from 'react'

const Progress = () => {
  const progressRef = useRef<HTMLProgressElement | null>(null)
  useEffect(() => {
    progressRef?.current?.setAttribute('indeterminate', 'true')
  }, [progressRef])
  return <progress ref={progressRef} />
}

export default Progress
