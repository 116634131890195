import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { startOfDay } from 'date-fns'
import { TripTypeEnum, Dict, Location, Id } from '@memberapp/models'
import { useQuery } from 'react-query'
import { UserType } from '../../models'
import DateFilter from '../../components/DateFilter'
import BoatFilter from '../../components/BoatFilter'
import BasicSelect from '../../components/BasicSelect'
import { getLocations, queryKey } from '../../services/locationsSvc'

type Props = {
  setQueryParams: (query: Dict<unknown>) => void | React.Dispatch<React.SetStateAction<Dict<unknown>>>
  filters: Dict<unknown>
  kgsPerBasket: number
  setKgsPerBasket: (val: number) => void
  defaultKgsPerBasket: number
}

type LocationType = Location & Id

const today = startOfDay(new Date())

export const dfltState: Dict<unknown> = {
  userId: '',
  start: today,
  type: TripTypeEnum.Trip,
  location: [],
  locationGrading: '',
}

const orderedTripTypes = [TripTypeEnum.Trip, TripTypeEnum.HangingOut, TripTypeEnum.NotGoing, TripTypeEnum.Favourite]

type MultiSelectOption = {
  label: string
  value: string
}

const FilterBar: React.FC<Props> = ({
  setQueryParams,
  filters,
  kgsPerBasket,
  setKgsPerBasket,
  defaultKgsPerBasket,
}) => {
  const { data: allLocations } = useQuery<LocationType[]>(queryKey, () => getLocations())
  const selectedLocations = useMemo<Dict<boolean>>(() => {
    const d: Dict<boolean> = {}
    if ((filters?.location as string[])?.length) {
      const t = filters?.location as string[]
      t.forEach((f) => (d[f] = true))
    }
    return d
  }, [filters])

  const [landLocations, boatLocations] = useMemo<[MultiSelectOption[], MultiSelectOption[]]>(() => {
    if (!allLocations?.length) {
      return [[], []]
    }
    const locationSorter = (a: LocationType, b: LocationType) => a.name.localeCompare(b.name)
    const landBased = allLocations
      .filter((f) => !f.isCarrierBoat)
      .sort(locationSorter)
      .map((m) => ({ label: m.name, value: `${m._id}` } as MultiSelectOption))
    const carrierBased = allLocations
      .filter((f) => f.isCarrierBoat)
      .sort(locationSorter)
      .map((m) => ({ label: m.name, value: `${m._id}` } as MultiSelectOption))
    return [landBased, carrierBased]
  }, [allLocations])

  const onChange = useCallback(
    (e: UserType | string[] | string | Date | boolean | null, name: string) => {
      let val: string | string[] | Date | null = null
      switch (name) {
        case 'userId':
          val = (e as UserType)?._id || ''
          break
        case 'date':
          val = e ? (e as Date) : null
          break
        default:
          val = e ? (e as string) : ''
          break
      }
      setQueryParams({ ...filters, [name]: val })
    },
    [setQueryParams, filters]
  )

  const handleTypeChanged = useCallback(
    (e: string) => {
      if (!setQueryParams) {
        return
      }
      setQueryParams({ ...filters, type: e || '' })
    },
    [setQueryParams, filters]
  )

  const handleGradingChanged = useCallback(
    (e: string) => {
      if (!setQueryParams) {
        return
      }
      setQueryParams({ ...filters, locationGrading: e || '' })
    },
    [setQueryParams, filters]
  )

  const clearQueryParams = () => {
    setQueryParams(dfltState)
    setKgsPerBasket(defaultKgsPerBasket)
  }

  const onMultiSelect = (v: string) => {
    let location = filters.location as string[]
    if (location.indexOf(v) >= 0) {
      location = location.filter((f) => f !== v)
    } else {
      location = [...location, v]
    }
    setQueryParams({ ...filters, location })
  }

  const handleKgsChange = (e: SyntheticEvent) => {
    const { target } = e
    if (target) {
      const { value } = target as HTMLInputElement
      const val = parseInt(value)
      if (!value || isNaN(val)) {
        setKgsPerBasket(defaultKgsPerBasket)
      } else {
        setKgsPerBasket(val)
      }
    }
  }

  return (
    <div className="filter-row">
      <label>Offload Date</label>
      <DateFilter value={filters.start as Date} onChange={(e: Date | null) => onChange(e, 'start')} />
      <label>Boat</label>
      <BoatFilter value={filters.userId as string} onChange={(e: UserType | null) => onChange(e, 'userId')} />
      <label>Type</label>
      <BasicSelect value={(filters.type as string) || ''} onChange={handleTypeChanged}>
        <option value="">Any</option>
        {orderedTripTypes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </BasicSelect>
      <label>Landing Area</label>
      <details className="dropdown">
        <summary aria-haspopup="listbox">
          {(filters?.location as string[])?.length ? `(${(filters?.location as string[])?.length}) selected` : 'All'}
        </summary>
        <ul role="listbox">
          <li>
            <strong>Land Based</strong>
          </li>
          {landLocations.map((m: MultiSelectOption) => (
            <li key={m.value} onClick={() => onMultiSelect(m.value)}>
              <input type="checkbox" checked={selectedLocations[m.value]} style={{ pointerEvents: 'unset' }} />
              <label>{m.label}</label>
            </li>
          ))}
          <li>
            <strong>Carrier Boats</strong>
          </li>
          {boatLocations.map((m: MultiSelectOption) => (
            <li key={m.value} onClick={() => onMultiSelect(m.value)}>
              <input type="checkbox" checked={selectedLocations[m.value]} style={{ pointerEvents: 'unset' }} />
              <label>{m.label}</label>
            </li>
          ))}
        </ul>
      </details>
      <label data-tooltip="Location Grading">Grading</label>
      <BasicSelect value={(filters.locationGrading as string) || ''} onChange={handleGradingChanged}>
        <option value="">ANY</option>
        <option value="north">NORTH</option>
        <option value="south">SOUTH</option>
      </BasicSelect>
      <label>Kgs/Basket</label>
      <input type="number" value={kgsPerBasket as number} onChange={handleKgsChange} />
      <a href="#" onClick={clearQueryParams}>
        Clear filters
      </a>
    </div>
  )
}

export default FilterBar
