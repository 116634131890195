import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MsalProvider } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

import { AuthContainer } from './containers/AuthContainer'
import { BUILD_ID, ENV, VERSION } from './config'
import AppRoutes, { NavLinks } from './routes'
import './App.scss'
import ThemeSwitcher from './components/ThemeSwitcher'

const now = new Date()
const queryClient = new QueryClient()

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <AuthContainer.Provider>
        <QueryClientProvider client={queryClient}>
          <header>
            <h1>Member App Admin Portal</h1>
            {ENV !== 'PROD' ? <div className="env-badge">{ENV}</div> : ''}
          </header>
          <aside>
            <NavLinks />
          </aside>
          <div className="content">
            <AppRoutes />
          </div>
          <footer>
            <span>&#169; {now?.getFullYear()}</span>
            <span>
              v{VERSION}.{BUILD_ID} {ENV}
            </span>
          </footer>
          <ThemeSwitcher />
        </QueryClientProvider>
      </AuthContainer.Provider>
    </MsalProvider>
  )
}

export default App
