import { Dict, GradePrice } from '@memberapp/models'
import { addDays, startOfDay, format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Send } from '../../components/Icons'
import ConfirmActionModal from '../../components/modals/ConfirmActionModal'
import Progress from '../../components/Progress'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'
import { BeachPriceType } from '../../models'
import {
  getBeachPriceGrades,
  getBeachPrices,
  queryKey,
  triggerManualBeachPriceNotification,
} from '../../services/beachPricesSvc'
import FilterBar from './FilterBar'

type BeachPriceDataType = string | Date | number | null
const lastWeek = addDays(startOfDay(new Date()), -7)

const qmProps: QMProps<BeachPriceType> = {
  queryKey,
  listFn: getBeachPrices,
  addFn: (o: BeachPriceType) => Promise.resolve(o),
  editFn: (o: BeachPriceType) => Promise.resolve(o),
  defaultArgs: { start: lastWeek },
}

const BeachPrices: React.FC = () => {
  const { data: GRADE_KEYS } = useQuery('GRADE_KEYS', getBeachPriceGrades)
  const { data, reset, error, isLoading, setQueryParams } = useQueryAndMutations<BeachPriceType>(qmProps)
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>({ ...qmProps.defaultArgs })
  const [beachPriceForNotifcation, setBeachPriceForNotification] = useState<Dict<unknown> | null>(null)

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )

  const beachPrices = useMemo<Dict<BeachPriceDataType>[]>(() => {
    const output: Dict<BeachPriceDataType>[] = []
    if (!GRADE_KEYS?.length || !data?.length) {
      return output
    }
    const emptyPrices: Dict<BeachPriceDataType> = {}
    GRADE_KEYS.forEach((key: string) => (emptyPrices[key] = null))
    if (data?.length && GRADE_KEYS?.length) {
      data.forEach((beachPrice: BeachPriceType) => {
        const tmp: Dict<BeachPriceDataType> = { ...emptyPrices }
        beachPrice.prices.forEach((f: GradePrice) => (tmp[f.grade] = f.price))
        output.push({
          _id: `${beachPrice._id}`,
          date: beachPrice.date as Date,
          lastNotificationSentAt: beachPrice.lastNotificationSentAt,
          ...tmp,
        })
      })
    }
    return output
  }, [GRADE_KEYS, data])

  const triggerNotification = (id: string) => {
    triggerManualBeachPriceNotification(id)
    setBeachPriceForNotification(null)
  }

  return (
    <div>
      <hgroup>
        <h2>Beach Prices</h2>
        <h3>&nbsp;</h3>
      </hgroup>

      <FilterBar filters={queryParams} setQueryParams={handleSetQueryParams} />

      {/* api state */}
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>Date</th>
            {GRADE_KEYS?.map((gk) => (
              <th key={gk}>{gk}</th>
            ))}
            <th>Sent At</th>
            <th>Resend</th>
          </tr>
        </thead>
        <tbody>
          {beachPrices?.map((obj: Dict<unknown>) => (
            <tr key={`${obj._id}`}>
              <td>{format(obj.date as Date, 'd MMM yyyy')}</td>
              {GRADE_KEYS?.map((gk) => (
                <td key={gk}>{`${obj[gk] || ''}`}</td>
              ))}
              <td>
                {obj.lastNotificationSentAt !== null
                  ? format(new Date(obj.lastNotificationSentAt as Date), 'd MMM yyyy HH:mm:ss')
                  : ''}
              </td>
              <td>
                <a href="#" onClick={() => setBeachPriceForNotification(obj)}>
                  <Send />
                </a>
              </td>
            </tr>
          ))}
          {!beachPrices?.length ? (
            <tr>
              <td colSpan={(GRADE_KEYS?.length || 0) + 3}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>
      {beachPriceForNotifcation ? (
        <ConfirmActionModal
          title="Send Notification?"
          message={`Send notifications for beach prices ${format(
            beachPriceForNotifcation.date as Date,
            'dd MMM yyyy'
          )}?`}
          cancelAction={() => setBeachPriceForNotification(null)}
          confirmAction={() => triggerNotification(beachPriceForNotifcation._id as string)}
        />
      ) : (
        []
      )}
    </div>
  )
}

export default BeachPrices
