import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Dict, SettingsDefaultKeysEnum, Trip } from '@memberapp/models'
import Progress from '../../components/Progress'
import { addTrip, getTrips, editTrip, queryKey, deleteTrip, enableTrip } from '../../services/tripSvc'
import useModal, { ShowModal } from '../../hooks/useModal'
import useQueryAndMutations, { QMProps } from '../../hooks/useQueryAndMutations'

import { TripContainer } from '../../containers/TripContainer'
import TripWizard from '../../components/modals/TripWizard'

import { EmptyTrip, TripType } from '../../models'
import SortButton from '../../components/SortButton'
import { getUsersOnBreak, usersOnBreakQueryKey } from '../../services/userSvc'
import NotGoingModal from '../../components/modals/NotGoingModal'
import { getSettings } from '../../services/settingsSvc'
import { sortTripsByOffloadTime } from '../../utils/tripSort'
import useDeleteEnable from '../../hooks/useDeleteEnable'
import TripsSummaryRow from './TripsSummaryRow'
import FilterBar, { dfltState } from './FilterBar'
import TripRow from './TripRow'

const qmProps: QMProps<TripType> = {
  queryKey,
  listFn: getTrips,
  addFn: addTrip,
  editFn: editTrip,
  defaultArgs: { ...dfltState },
}

const Trips: React.FC = () => {
  const queryClient = useQueryClient()
  const {
    data,
    reset: queryReset,
    error: queryError,
    isLoading: isQueryLoading,
    setQueryParams,
    addMutate,
    editMutate,
  } = useQueryAndMutations<TripType>(qmProps)
  const { data: usersOnBreak = [] } = useQuery([usersOnBreakQueryKey], getUsersOnBreak)

  const { showModal, showAdd, showEdit, handleClose, itemToEdit } = useModal<TripType>(EmptyTrip)
  const [showNotGoingModal, setShowNotGoingModal] = useState(false)
  const { data: settings } = useQuery(queryKey, getSettings)

  const defaultKgsPerBasket = useMemo(() => {
    return +(settings?.find((v) => v._id === SettingsDefaultKeysEnum.DEFAULT_KGS_PER_BASKET)?.value || 0)
  }, [settings])

  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>(dfltState)
  const [sortData, setSortData] = useState({ column: 'offloadTime', isAsc: true })
  const [kgsPerBasket, setKgsPerBasket] = useState<number>(0)

  const { ConfirmModals, DeleteEnableActions, isDeleteEnableLoading, deleteEnableError, deleteEnableReset } =
    useDeleteEnable<Trip>({
      name: 'Trip',
      data,
      queryKey,
      filters: queryParams,
      deleteFunc: deleteTrip,
      enableFunc: enableTrip,
    })

  const reset = useCallback(() => {
    if (queryClient) {
      deleteEnableReset()
      queryReset()
      queryClient.resetQueries()
    }
  }, [queryReset, queryClient, deleteEnableReset])

  const isLoading = useMemo(() => {
    return isQueryLoading || isDeleteEnableLoading
  }, [isQueryLoading, isDeleteEnableLoading])

  const error = useMemo(() => {
    return queryError || deleteEnableError
  }, [queryError, deleteEnableError])

  useEffect(() => {
    setKgsPerBasket(defaultKgsPerBasket)
  }, [defaultKgsPerBasket])

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )

  const sortedData = useMemo<TripType[]>(() => {
    if (!data?.length) {
      return []
    }
    const { column, isAsc } = sortData

    if (column === 'offloadTime') {
      return sortTripsByOffloadTime(data, isAsc)
    } else {
      if (isAsc) {
        return data.sort((a: TripType, b: TripType) => a.user.boatName.localeCompare(b.user.boatName))
      }
      return data.sort((a: TripType, b: TripType) => b.user.boatName.localeCompare(a.user.boatName))
    }
  }, [data, sortData])

  const sortByColumn = useCallback(
    (field: string) => {
      const { column, isAsc } = sortData
      if (column === field) {
        setSortData({ ...sortData, isAsc: !isAsc })
      } else {
        setSortData({ column: field, isAsc: true })
      }
    },
    [sortData, setSortData]
  )

  const { column, isAsc } = sortData

  return (
    <div>
      <hgroup>
        <h2>Trips</h2>
        <a href="#" className="outline" onClick={showAdd}>
          Add New
        </a>
        <a href="#" className="outline float-right" onClick={() => setShowNotGoingModal(true)}>
          Not Going
        </a>
        <h6></h6>
      </hgroup>

      <FilterBar
        {...{
          kgsPerBasket,
          setKgsPerBasket,
          filters: queryParams,
          setQueryParams: handleSetQueryParams,
          defaultKgsPerBasket,
        }}
      />

      {/* api state */}
      {isLoading && !error ? <Progress /> : []}
      {error ? <p onClick={() => reset()}>{(error as { message?: string })?.message}</p> : []}

      <table role="grid">
        <thead>
          <tr>
            <th>
              Boat&nbsp;
              <SortButton
                {...{ sortAscending: column === 'boatName' && isAsc, toggleSort: () => sortByColumn('boatName') }}
              />
            </th>
            <th>Type</th>
            <th>Landing Area</th>
            <th>
              Offload Time
              <SortButton
                {...{ sortAscending: column === 'offloadTime' && isAsc, toggleSort: () => sortByColumn('offloadTime') }}
              />
            </th>
            <th>Last Estimated</th>
            <th className="baskets">Est. Baskets</th>
            <th className="baskets">Predicted</th>
            <th className="baskets">Actual Baskets</th>
            <th>Created By</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((obj: TripType) => (
            <TripRow trip={obj} showEdit={showEdit} key={obj?._id}>
              {DeleteEnableActions(obj as Trip)}
            </TripRow>
          ))}
          {!sortedData?.length ? (
            <tr>
              <td colSpan={7}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
        <TripsSummaryRow trips={sortedData} kgsPerBasket={kgsPerBasket} />
      </table>
      {showNotGoingModal ? (
        <NotGoingModal usersOnBreak={usersOnBreak} onClose={() => setShowNotGoingModal(false)} />
      ) : (
        []
      )}
      {showModal !== ShowModal.None ? (
        <TripContainer.Provider initialState={{ obj: itemToEdit, modalType: showModal, addMutate, editMutate }}>
          <TripWizard handleClose={handleClose} />
        </TripContainer.Provider>
      ) : (
        []
      )}
      {ConfirmModals()}
    </div>
  )
}

export default Trips
