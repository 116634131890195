import { FC, useCallback } from 'react'
import { format } from 'date-fns'
import { UserType } from '../../models'

interface Props {
  onClose: () => void
  usersOnBreak: UserType[]
}
const NotGoingModal: FC<Props> = ({ onClose, usersOnBreak }) => {
  const cancel = useCallback(() => onClose && onClose(), [onClose])

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            Not Going
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Not Going Until</th>
            </tr>
          </thead>
          <tbody>
            {usersOnBreak.map(({ _id, name, breakUntil }) => {
              return (
                <tr key={_id}>
                  <td>{name}</td>
                  <td>{format(new Date(breakUntil as Date), 'dd MMM yyyy')}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <footer>
          <button onClick={cancel} className="small">
            Close
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default NotGoingModal
