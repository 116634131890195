import React, { SyntheticEvent, useCallback } from 'react'
import { format, startOfDay } from 'date-fns'

type DateFilterProps = {
  value: Date | null
  onChange: (value: Date | null) => void | React.Dispatch<React.SetStateAction<Date>>
}

const DateFilter: React.FC<DateFilterProps> = ({ value, onChange }) => {
  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target) {
        return
      }
      const { value } = event?.target as HTMLInputElement
      const newVal = value ? startOfDay(new Date(value)) : null
      onChange(newVal)
    },
    [onChange]
  )

  return <input type="date" value={value ? format(value, 'yyyy-MM-dd') : ''} onChange={handleChange} />
}

export default DateFilter
