"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zonedIsTommorrow = exports.zonedFormat = exports.zonedStartOfTomorrow = exports.zonedStartOfDay = void 0;
const date_fns_tz_1 = require("date-fns-tz");
const date_fns_1 = require("date-fns");
const constants_1 = require("../constants");
const zonedStartOfDay = (inputDate, tz = constants_1.defaultTz) => {
    const rawDate = new Date(inputDate);
    const zonedDate = (0, date_fns_tz_1.utcToZonedTime)(rawDate, tz);
    const zonedStart = (0, date_fns_1.startOfDay)(zonedDate);
    return (0, date_fns_tz_1.zonedTimeToUtc)(zonedStart, tz);
};
exports.zonedStartOfDay = zonedStartOfDay;
const zonedStartOfTomorrow = (tz = constants_1.defaultTz) => {
    const start = (0, exports.zonedStartOfDay)(new Date(), tz);
    return (0, date_fns_1.addDays)(start, 1);
};
exports.zonedStartOfTomorrow = zonedStartOfTomorrow;
const zonedFormat = (date, formatString, tz = constants_1.defaultTz) => {
    return (0, date_fns_tz_1.formatInTimeZone)(date, tz, formatString);
};
exports.zonedFormat = zonedFormat;
const zonedIsTommorrow = (date, tz = constants_1.defaultTz) => {
    const zonedTomorrow = (0, exports.zonedStartOfTomorrow)();
    const zonedStartOfDate = (0, exports.zonedStartOfDay)(date, tz);
    return zonedTomorrow.getTime() === zonedStartOfDate.getTime();
};
exports.zonedIsTommorrow = zonedIsTommorrow;
