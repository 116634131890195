import React, { PropsWithChildren, useMemo } from 'react'
import { Routes, Route, Link, useLocation } from 'react-router-dom'
import BaitView from './views/bait/Bait'
import BaitOrders from './views/baitorders/BaitOrders'
import Home from './views/home/Home'
import Locations from './views/locations/Locations'
import News from './views/news/News'
import Trips from './views/trips/Trips'
import Users from './views/users/Users'
import { AuthContainer, Roles } from './containers/AuthContainer'
import AuthenticatedPage from './components/AuthenticatedPage'
import AuthorisedRoute, { AuthorisedRouteType } from './components/AuthorisedRoute'
import BeachPrices from './views/beachPrices/BeachPrices'
import Reports from './views/reports/Reports'
import { ReactComponent as Logo } from './gfc-logo-wide.svg'
import Settings from './views/settings/Settings'
import UserUpload from './views/users/UserUpload'
import ResetPassword from './views/resetpassword/ResetPassword'

const adminRoles: string[] = [Roles.Admin]
const depotManagerRoles: string[] = [Roles.Admin, Roles.DepotManager]

type UserRoleLinkProps = { path: string; match: string; roles: string[]; allowedRoles: string[] }

const UserRoleLink: React.FC<PropsWithChildren<UserRoleLinkProps>> = ({
  path,
  match,
  roles,
  allowedRoles,
  children,
}) => {
  return roles.some((role) => allowedRoles.find((allowedRole) => allowedRole === role)) ? (
    <li className={path === match ? 'active' : ''}>{children}</li>
  ) : null
}

const LogoutBtn: React.FC<{ logout: () => void }> = ({ logout }) => {
  return (
    <button onClick={logout} style={{ marginTop: 'auto' }} className="secondary">
      Log out
    </button>
  )
}

export const NavLinks = () => {
  const { pathname } = useLocation()
  const path = useMemo<string>(() => (pathname?.length ? pathname.toLowerCase().split('/')[1] : ''), [pathname])
  const { roles, logout, account } = AuthContainer.useContainer()

  return (
    <>
      <Link to="/" className="homeLink">
        <Logo />
      </Link>
      {/* <UserRoleLink {...{ roles, allowedRoles: depotManagerRoles, path, match: '' }}>
      </UserRoleLink> */}
      <nav>
        <ul>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'users' }}>
            <Link to="/users">Users</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'locations' }}>
            <Link to="/locations">Locations</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'bait' }}>
            <Link to="/bait">Bait</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'news' }}>
            <Link to="/news">News</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: depotManagerRoles, path, match: 'trips' }}>
            <Link to="/trips">Trips</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: depotManagerRoles, path, match: 'baitorders' }}>
            <Link to="/baitorders">Bait Orders</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'beachprices' }}>
            <Link to="/beachprices">Beach Prices</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: depotManagerRoles, path, match: 'reports' }}>
            <Link to="/reports">Reports</Link>
          </UserRoleLink>
          <UserRoleLink {...{ roles, allowedRoles: adminRoles, path, match: 'settings' }}>
            <Link to="/settings">Settings</Link>
          </UserRoleLink>
        </ul>
        {account ? <LogoutBtn logout={logout} /> : []}
      </nav>
    </>
  )
}

const Page: React.FC<PropsWithChildren<AuthorisedRouteType>> = ({ allowedRoles, children }) => {
  return (
    <AuthenticatedPage>
      <AuthorisedRoute allowedRoles={allowedRoles}>{children}</AuthorisedRoute>
    </AuthenticatedPage>
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/users"
        element={
          <Page allowedRoles={adminRoles}>
            <Users />
          </Page>
        }
      />
      <Route
        path="/users/upload"
        element={
          <Page allowedRoles={adminRoles}>
            <UserUpload />
          </Page>
        }
      />
      <Route
        path="/locations"
        element={
          <Page allowedRoles={adminRoles}>
            <Locations />
          </Page>
        }
      />
      <Route
        path="/bait"
        element={
          <Page allowedRoles={adminRoles}>
            <BaitView />
          </Page>
        }
      />

      <Route
        path="/news"
        element={
          <Page allowedRoles={adminRoles}>
            <News />
          </Page>
        }
      />

      <Route
        path="/trips"
        element={
          <Page allowedRoles={depotManagerRoles}>
            <Trips />
          </Page>
        }
      />

      <Route
        path="/baitorders"
        element={
          <Page allowedRoles={depotManagerRoles}>
            <BaitOrders />
          </Page>
        }
      />

      <Route
        path="/beachprices"
        element={
          <Page allowedRoles={adminRoles}>
            <BeachPrices />
          </Page>
        }
      />
      <Route
        path="/settings"
        element={
          <Page allowedRoles={adminRoles}>
            <Settings />
          </Page>
        }
      />
      <Route
        path="/reports"
        element={
          <Page allowedRoles={depotManagerRoles}>
            <Reports />
          </Page>
        }
      />
      <Route
        path="/"
        element={
          <Page allowedRoles={depotManagerRoles}>
            <Home />
          </Page>
        }
      />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  )
}

export default AppRoutes
