import { Settings, SettingsDefaultKeysEnum } from '@memberapp/models'
import { SyntheticEvent, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { upsertSetting, queryKey } from '../../services/settingsSvc'

interface Props {
  onClose: () => void
  obj: Settings
}

const SettingsModal: React.FC<Props> = ({ onClose, obj }) => {
  const queryClient = useQueryClient()
  const [value, setValue] = useState<string>(obj.value)
  const { mutate: update } = useMutation((m: Settings) => upsertSetting(m._id, m.value), {
    onSuccess: (newVal: Settings) => {
      queryClient.setQueryData([queryKey, newVal._id], newVal)
      queryClient.invalidateQueries(queryKey)
      onClose()
    },
  })

  const id = useMemo(() => obj?._id || '', [obj])

  const saveDisabled = useMemo(() => !value, [value])

  const cancel = () => {
    onClose()
  }

  const save = () => {
    if (!saveDisabled) {
      update({ _id: id, value } as Settings)
    }
  }

  const handleChange = (event: SyntheticEvent) => {
    if (!event.target) {
      return
    }
    const { value: val, type, checked } = event.target as HTMLInputElement
    if (type === 'checkbox') {
      setValue(`${checked}`.toLowerCase())
    } else {
      setValue(val)
    }
  }

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            Edit Setting
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          <label>{id}</label>
          {id === SettingsDefaultKeysEnum.DEFAULT_BEACH_PRICES_NOTIFICATION_24H_TIME ||
          id === SettingsDefaultKeysEnum.TRIP_UPDATE_EMAIL_NOTIFICATION_24H_TIME ? (
            <input type="time" value={value} onChange={handleChange} step={300} />
          ) : (
            []
          )}
          {id === SettingsDefaultKeysEnum.BEACH_PRICE_EMAIL_NOTIFICATION_ENABLED ? (
            <input type="checkbox" checked={value === 'true'} onChange={handleChange} step={300} />
          ) : (
            []
          )}
          {id === SettingsDefaultKeysEnum.TRIP_PREDICTION_WEIGHTING_PERCENTAGE_LAST_3_TRIPS ||
          id === SettingsDefaultKeysEnum.TRIP_UPDATE_EMAIL_NOTIFICATION_RECIPIENT ? (
            <input type="text" value={value} onChange={handleChange} />
          ) : (
            []
          )}
          {id === SettingsDefaultKeysEnum.DEFAULT_KGS_PER_BASKET ? (
            <input type="number" onChange={handleChange} value={value} />
          ) : (
            []
          )}
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} aria-disabled={saveDisabled} disabled={saveDisabled} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}
export default SettingsModal
