import { BaitOrder, BaitOrderType as BaitOrderTypeEnum } from '@memberapp/models'
import React, { useCallback } from 'react'
import { BaitOrderContainer } from '../../containers/BaitOrderContainer'
import { TripContainer } from '../../containers/TripContainer'
import { ShowModal } from '../../hooks/useModal'
import { BaitOrderType } from '../../models'
import BaitOrderModal from './BaitOrderModal'
import TripModal from './TripModal'

type Props = {
  handleClose: () => void
}

const TripWizard: React.FC<Props> = ({ handleClose }) => {
  const { trip, showBaitOrder, setShowBaitOrder, setTrip, modalType } = TripContainer.useContainer()

  const closeBaitOrderModal = useCallback(
    (baitOrder: BaitOrderType | null) => {
      if (!setTrip || !setShowBaitOrder) {
        return
      }
      if (baitOrder) {
        setTrip({ ...trip, baitOrder })
      }
      setShowBaitOrder(false)
    },
    [setTrip, trip, setShowBaitOrder]
  )

  const baitOrder: BaitOrder | null = trip.baitOrder

  if (baitOrder) {
    baitOrder.name = trip.baitOrder?.type === BaitOrderTypeEnum.Favourite ? trip.baitOrder.name : ''
    baitOrder.collectionLocation = baitOrder.collectionLocation || trip.offloadLocation || null
  }

  return (
    <>
      <TripModal onClose={handleClose} />
      {showBaitOrder ? (
        <BaitOrderContainer.Provider
          initialState={{
            obj: baitOrder,
            modalType,
          }}>
          <BaitOrderModal
            onClose={closeBaitOrderModal}
            showBoatSelector={!trip?.user?.boatName}
            showTypeSelector={baitOrder?.type === BaitOrderTypeEnum.Scheduled}
            showFavouriteSelector={true}
            isEditMode={modalType === ShowModal.Edit}
            isTripBaitOrder={true}
          />
        </BaitOrderContainer.Provider>
      ) : (
        []
      )}
    </>
  )
}

export default TripWizard
