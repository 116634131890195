import { MemberCategory, Dict, NotificationTopics } from '@memberapp/models'
import React, { SyntheticEvent } from 'react'
import BasicSelect from '../../components/BasicSelect'

interface Props {
  filter: Dict<string>
  handleFilterChange: React.Dispatch<React.SetStateAction<Dict<string>>>
}

const FilterBar: React.FC<Props> = ({ filter, handleFilterChange }) => {
  const handleMemberCategoryChange = (val: string) => {
    handleFilterChange({ ...filter, memberCategory: val || '' })
  }

  const handleDisabledNotificationsChange = (val: string) => {
    handleFilterChange({ ...filter, notificationsDisabledFilter: val })
  }

  const onChange = (event: SyntheticEvent) => {
    if (!event?.target) {
      return
    }
    const { name, value } = event.target as HTMLInputElement
    handleFilterChange({ ...filter, [name]: value || '' })
  }

  return (
    <div className="filter-row">
      <label>Name</label>
      <input type="text" name="name" value={filter?.name || ''} onChange={onChange} />
      <label>Email</label>
      <input type="email" name="email" value={filter?.email || ''} onChange={onChange} />
      <label>Member Category</label>
      <BasicSelect value={filter?.memberCategory || ''} onChange={handleMemberCategoryChange}>
        <option value="">All</option>
        <option value={`${MemberCategory.Skipper}`}>Skipper</option>
        <option value={`${MemberCategory.NonSkipper}`}>NonSkipper</option>
      </BasicSelect>
      <label>Boat Name</label>
      <input type="text" name="boatName" value={filter?.boatName || ''} onChange={onChange} />
      <label>Disabled Notifications</label>
      <BasicSelect value={filter?.notificationsDisabledFilter || ''} onChange={handleDisabledNotificationsChange}>
        <option>Select...</option>
        <option value={`${NotificationTopics.BeachPrice}`}>Beach Prices</option>
        <option value={`${NotificationTopics.NewsEvent}`}>Events</option>
        <option value={`${NotificationTopics.NewsMessage}`}>Messages</option>
      </BasicSelect>
      <a href="#" onClick={() => handleFilterChange({})}>
        Clear filters
      </a>
    </div>
  )
}

export default FilterBar
