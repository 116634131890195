import { Dict } from '@memberapp/models'
import { BaitOrderType } from '../models'
import { buildQueryParams, Get, Post, Put, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'baitOrder'
const url = 'baitorders'

const mapResponse = (res: BaitOrderType[]): BaitOrderType[] => {
  return res.map(mapBaitOrder)
}

const mapBaitOrder = (obj: BaitOrderType): BaitOrderType => {
  return {
    ...obj,
    collectionDate: obj.collectionDate ? new Date(obj.collectionDate) : null,
  }
}

export const getBaitOrders = async (query?: Dict<unknown>) =>
  Get<BaitOrderType[]>(buildQueryParams(url, query)).then(mapResponse)

export const addBaitOrder = async (obj: BaitOrderType) => Post<BaitOrderType>(url, obj).then(mapBaitOrder)

export const editBaitOrder = async (obj: BaitOrderType) =>
  Put<BaitOrderType>(`${url}/${obj._id}`, obj).then(mapBaitOrder)

export const getFavourites = async (userId: string) =>
  userId ? Get<BaitOrderType[]>(`${url}/favourites/${userId}`).then(mapResponse) : Promise.resolve([])

export const getBaitOrderInfo = async (utcDate: Date, locationId = '') => {
  const query: Dict<unknown> = { utcDate }
  if (locationId) {
    query.locationId = locationId
  }
  return untypedRequest(RequestMethod.GET, buildQueryParams(`${url}/reports/summaryByBoat`, query))
}

export const getBaitOrderSummary = async (utcDate: Date, locationId = '') => {
  const query: Dict<unknown> = { date: utcDate }
  if (locationId) {
    query.location = locationId
  }
  return untypedRequest(RequestMethod.GET, buildQueryParams(`${url}/reports/summaryByBaitType`, query))
}

export const deleteBaitOrder = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)
