import { Dict, BaitOrderType } from '@memberapp/models'
import { startOfDay } from 'date-fns'
import React, { useCallback } from 'react'
import BasicSelect from '../../components/BasicSelect'
import BoatFilter from '../../components/BoatFilter'
import DateFilter from '../../components/DateFilter'
import LocationFilter from '../../components/LocationsFilter'
import { LocationType, UserType } from '../../models'

type Props = {
  setQueryParams: (query: Dict<unknown>) => void | React.Dispatch<React.SetStateAction<Dict<unknown>>>
  filters: Dict<unknown>
}

const today = startOfDay(new Date())

export const dfltState: Dict<unknown> = {
  userId: '',
  date: today,
  location: '',
  type: '',
  locationGrading: '',
}

const FilterBar: React.FC<Props> = ({ setQueryParams, filters = dfltState }) => {
  const onChange = useCallback(
    (e: UserType | LocationType | string | Date | null, name: string) => {
      let val: string | Date | null = null
      switch (name) {
        case 'userId':
          val = (e as UserType)?._id || ''
          break
        case 'location':
          val = (e as LocationType)?._id || ''
          break
        case 'date':
          val = e ? (e as Date) : null
          break
        default:
          val = e ? (e as string) : ''
          break
      }
      setQueryParams({ ...filters, [name]: val })
    },
    [setQueryParams, filters]
  )

  const handleGradingChanged = useCallback(
    (e: string) => {
      if (!setQueryParams) {
        return
      }
      setQueryParams({ ...filters, locationGrading: e || '' })
    },
    [setQueryParams, filters]
  )

  const clearQueryParams = useCallback(() => {
    setQueryParams(dfltState)
  }, [setQueryParams])

  return (
    <div className="filter-row">
      <label>Collection Date</label>
      <DateFilter value={filters.date as Date} onChange={(e: Date | null) => onChange(e, 'date')} />
      <label>Boat</label>
      <BoatFilter value={filters.userId as string} onChange={(e: UserType | null) => onChange(e, 'userId')} />
      <label>Type</label>
      <BasicSelect value={filters.type as string} onChange={(e: string) => onChange(e, 'type')}>
        <option value="">All Types</option>
        <option value={`${BaitOrderType.Favourite}`}>Favourite</option>
        <option value={`${BaitOrderType.Scheduled}`}>Scheduled</option>
        <option value={`${BaitOrderType.Trip}`}>Trip</option>
      </BasicSelect>
      <label>Location</label>
      <LocationFilter
        value={filters.location as string}
        onChange={(e: LocationType | null) => onChange(e, 'location')}
      />

      <label data-tooltip="Location Grading">Grading</label>
      <BasicSelect value={(filters.locationGrading as string) || ''} onChange={handleGradingChanged}>
        <option value="">ANY</option>
        <option value="north">NORTH</option>
        <option value="south">SOUTH</option>
      </BasicSelect>

      <a href="#" onClick={clearQueryParams}>
        Clear filters
      </a>
    </div>
  )
}
export default FilterBar
