import { useState } from 'react'
import BasicSelect from '../../../components/BasicSelect'
import { DownloadDocument } from '../../../components/Icons'
import { userNoLoginReport } from '../../../services/userSvc'

type Props = {
  downloadReport: (fn: () => Promise<Response>) => Promise<void>
}

const LOGIN_REPORT_OPTIONS = ['Last Week', 'Last Month', 'Last Year']

const UsersNoLogin: React.FC<Props> = ({ downloadReport }) => {
  const [notLoggedInBefore, setNotLoggedInBefore] = useState<string>('')

  const downloadUserNotLoggedIn = () => {
    downloadReport(() => userNoLoginReport(notLoggedInBefore))
  }

  return (
    <>
      <label>Last login at</label>
      <BasicSelect value={notLoggedInBefore} onChange={setNotLoggedInBefore}>
        <option value="">Never Logged In</option>
        {LOGIN_REPORT_OPTIONS.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </BasicSelect>
      <button onClick={downloadUserNotLoggedIn}>
        <DownloadDocument />
        Login Report
      </button>
      <span className="spacer" />
    </>
  )
}

export default UsersNoLogin
