import {
  Bait,
  BaitOrder,
  BaitOrderDelivery,
  BaitOrderItem,
  BaitOrderType as BaitOrderTypeEnum,
  Location,
  MemberCategory,
  News,
  NewsType as NewsTypeEnum,
  Trip,
  User,
  Id,
  BeachPrice,
  TripTypeEnum,
  Dict,
} from '@memberapp/models'
import { addDays, addHours, startOfDay, startOfToday, startOfTomorrow } from 'date-fns'

export interface BaitType extends Bait, Id {}
export interface UserType extends User, Id {}
export interface LocationType extends Location, Id {}
export interface NewsType extends News, Id {}
export interface TripType extends Trip, Id {}
export interface BaitOrderType extends BaitOrder, Id {}
export interface BeachPriceType extends BeachPrice, Id {}
export interface PredictedBasketsArg {
  percentageList: string[]
  trip: Trip
}
export interface predictedBasketDataType {
  value: number
  displayValue: string
}

const leftPad2 = (num: number): string => {
  const str = `000${num}`
  return str.substring(str.length - 2)
}

export const INITIAL_OFFLOAD_TIME = '01:00 AM'

export const getTimesDict = (): Dict<string> => {
  const dict: Dict<string> = {}
  Array(24 * 4)
    .fill(0)
    .forEach((el, i) => {
      const hour = Math.floor(i / 4)
      const quarterHr = i % 4
      dict[`${i * 15}`] = `${hour}:${leftPad2(quarterHr * 15)}`
    })
  return dict
}

export const EmptyLocation: LocationType = {
  name: 'Select a location',
  isCarrierBoat: false,
  _id: '',
} as LocationType

export const EmptyUser: UserType = {
  accountNumber: '',
  boatName: 'No boat',
  email: 'empty@no-user.com',
  name: 'Select Boat',
  memberCategory: MemberCategory.Skipper,
  password: '',
  notificationSettings: [],
  _id: '',
  deletedAt: null,
  lastLoginAt: null,
  crmId: '',
  breakUntil: null,
} as UserType

const EmptyBaitOrderItems: BaitOrderItem[] = []

export const EmptyBaitOrder: BaitOrderType = {
  _id: '',
  user: EmptyUser,
  deliveryType: BaitOrderDelivery.WhenIDeliver,
  collectionDate: startOfTomorrow(),
  collectionLocation: EmptyLocation,
  items: EmptyBaitOrderItems,
  type: BaitOrderTypeEnum.Trip,
} as BaitOrderType

export const EmptyTrip: TripType = {
  _id: '',
  user: EmptyUser,
  baitOrder: null,
  estimatedBaskets: 0,
  type: TripTypeEnum.Trip,
  offloadDate: startOfTomorrow(),
  offloadTime: INITIAL_OFFLOAD_TIME,
  offloadLocation: EmptyLocation,
  tripDate: startOfTomorrow(),
} as TripType

export const EmptyNews: NewsType = {
  _id: '',
  date: startOfToday(),
  title: '',
  body: '',
  type: NewsTypeEnum.Message,
  messageSummary: [],
  eventSummary: undefined,
  scheduledToSendAt: addHours(startOfDay(addDays(new Date(), 7)), 12),
} as NewsType

export const GRADES: Dict<string> = {
  AA: 'AA',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  '2nd': '2nd',
}
