import React, { useCallback, useMemo, useState } from 'react'
import { User, Id, Dict, NotificationTopics } from '@memberapp/models'
import { useMutation, useQueryClient } from 'react-query'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import Progress from '../../components/Progress'
import {
  deleteUser,
  passwordReset,
  getUsers,
  queryKey,
  enableUser,
  updateUserNotifications,
} from '../../services/userSvc'
import { LockReset, Pencil } from '../../components/Icons'
import ConfirmActionModal from '../../components/modals/ConfirmActionModal'
import useDeleteEnable from '../../hooks/useDeleteEnable'
import FilterActiveDeleted from '../../components/FilterActiveDeleted'
import useQueryAndMutations from '../../hooks/useQueryAndMutations'
import SortButton from '../../components/SortButton'
import UserModal from '../../components/modals/UserModal'
import FilterBar from './FilterBar'

type UserType = User & Id

const defaultSort: Dict<boolean | null> = {
  name: true,
  email: null,
  boat: null,
}

const userFriendlyNotificationSettings = (settings: string[]) => {
  const str = settings
    .join(', ')
    .replace(NotificationTopics.BeachPrice, 'Beach Prices')
    .replace(NotificationTopics.NewsEvent, 'Events')
    .replace(NotificationTopics.NewsMessage, 'Messages')
  return str
}

const Users: React.FC = () => {
  const queryClient = useQueryClient()
  const [queryParams, setViewQueryParams] = useState<Dict<unknown>>({ includeDeleted: false })
  const [sortBy, setSortBy] = useState<Dict<boolean | null>>(defaultSort)
  const [userForPasswordReset, setUserForPasswordReset] = useState<UserType | null>(null)
  const [clientFilter, setClientFilter] = useState<Dict<string>>({})
  const [userToEdit, setUserToEdit] = useState<UserType | null>(null)

  const {
    isLoading: isQueryLoading,
    data,
    error: queryError,
    setQueryParams,
  } = useQueryAndMutations({
    queryKey,
    listFn: getUsers,
    addFn: (obj: UserType) => Promise.resolve(obj),
    editFn: (obj: User & Id) => Promise.resolve(obj),
    defaultArgs: queryParams,
  })

  const handleSetQueryParams = useCallback(
    (dict: Dict<unknown>) => {
      setViewQueryParams(dict)
      setQueryParams(dict)
    },
    [setViewQueryParams, setQueryParams]
  )
  const { ConfirmModals, DeleteEnableActions, isDeleteEnableLoading, deleteEnableError, deleteEnableReset } =
    useDeleteEnable<UserType>({
      name: 'User',
      data,
      queryKey,
      filters: queryParams,
      deleteFunc: deleteUser,
      enableFunc: enableUser,
    })
  const {
    mutate: userPasswordReset,
    error: pwResetError,
    isLoading: pwResetLoading,
    reset: pwResetReset,
  } = useMutation((id: string) => passwordReset(id))

  const {
    mutate: mutateUserNotifications,
    error: userNotificationsErr,
    isLoading: userNotificationsLoading,
    reset: userNotificationsReset,
  } = useMutation((u: UserType) => updateUserNotifications(u))

  const users = useMemo<UserType[]>(() => {
    let tmp = [...(data || [])] as UserType[]
    if (clientFilter?.email) {
      const lowerEmail = clientFilter.email.toLowerCase()
      tmp = tmp.filter((f) => f.email.toLowerCase().indexOf(lowerEmail) > -1)
    }
    if (clientFilter?.name) {
      const lowerName = clientFilter.name.toLowerCase()
      tmp = tmp.filter((f) => f.name.toLowerCase().indexOf(lowerName) > -1)
    }
    if (clientFilter?.memberCategory) {
      tmp = tmp.filter((f) => f.memberCategory === clientFilter.memberCategory)
    }
    if (clientFilter?.boatName) {
      const lowerBoatName = clientFilter.boatName.toLowerCase()
      tmp = tmp.filter((f) => f.boatName.toLowerCase().indexOf(lowerBoatName) > -1)
    }
    if (clientFilter?.notificationsDisabledFilter) {
      tmp = tmp.filter((f) => !f.notificationSettings.includes(clientFilter.notificationsDisabledFilter))
    }
    return tmp as UserType[]
  }, [data, clientFilter])

  const isLoading = useMemo(() => {
    return isQueryLoading || isDeleteEnableLoading || pwResetLoading || userNotificationsLoading
  }, [isQueryLoading, isDeleteEnableLoading, pwResetLoading, userNotificationsLoading])

  const error = useMemo(() => {
    return queryError || deleteEnableError || pwResetError || userNotificationsErr
  }, [queryError, deleteEnableError, pwResetError, userNotificationsErr])

  const handlePasswordReset = (id: string) => {
    userPasswordReset(id, {
      onSuccess: () => {
        setUserForPasswordReset(null)
      },
    })
  }

  const reset = useCallback(() => {
    if (queryClient) {
      deleteEnableReset()
      pwResetReset()
      userNotificationsReset()
      queryClient.resetQueries()
    }
  }, [deleteEnableReset, queryClient, pwResetReset, userNotificationsReset])

  const sortedUsers = useMemo<UserType[]>(() => {
    if (!users?.length) {
      return []
    }
    const sortKey = Object.keys(sortBy).find((f) => sortBy[f] !== null)
    if (!sortKey) {
      return users
    }
    const ascending = sortBy[sortKey]
    let first: UserType
    let second: UserType
    return users.sort((a: UserType, b: UserType) => {
      first = ascending ? a : b
      second = ascending ? b : a
      return (({ ...first }[sortKey] as string) || '').localeCompare(({ ...second }[sortKey] as string) || '')
    })
  }, [users, sortBy])

  const toggleSort = useCallback(
    (key: string) => {
      if (!setSortBy) {
        return
      }
      const tmp: Dict<boolean | null> = { ...sortBy }
      tmp[key] = !tmp[key]
      Object.keys(tmp)
        .filter((f) => f !== key)
        .forEach((k) => (tmp[k] = null))
      setSortBy(tmp)
    },
    [sortBy, setSortBy]
  )

  const handleUserNotificationsSave = (u: UserType) => {
    mutateUserNotifications(u, {
      onSuccess: (obj) => {
        queryClient.setQueryData(queryKey, [
          ...(users || []).filter((f: UserType) => f._id !== (obj as UserType)._id),
          obj,
        ])
        queryClient.invalidateQueries()
        setUserToEdit(null)
      },
    })
  }

  return (
    <div>
      <hgroup>
        <h2>Users</h2>
        <h3>
          <Link to="/users/upload">Import from CSV</Link>
        </h3>
      </hgroup>
      <FilterBar filter={clientFilter} handleFilterChange={setClientFilter} />
      {isLoading ? <Progress /> : []}
      {error ? <p onClick={reset}>{(error as { message?: string })?.message}</p> : []}
      <table role="grid">
        <thead>
          <tr>
            <th>Account Number</th>
            <th>
              Name&nbsp;
              <SortButton {...{ sortAscending: sortBy.name === true, toggleSort: () => toggleSort('name') }} />
            </th>
            <th>
              Email&nbsp;
              <SortButton {...{ sortAscending: sortBy.email === true, toggleSort: () => toggleSort('email') }} />
            </th>
            <th>Member Category</th>
            <th>
              Boat Name&nbsp;
              <SortButton {...{ sortAscending: sortBy.boatName === true, toggleSort: () => toggleSort('boatName') }} />
            </th>
            <th>Last Login</th>
            <th>Notification Settings</th>
            <th>
              <FilterActiveDeleted filters={queryParams} setQueryParams={handleSetQueryParams} />
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers?.map((obj: UserType) => (
            <tr key={obj._id}>
              <td>{obj?.accountNumber}</td>
              <td>{obj?.name}</td>
              <td>{obj?.email}</td>
              <td>{obj?.memberCategory}</td>
              <td>{obj?.boatName}</td>
              <td>{obj?.lastLoginAt ? format(new Date(obj.lastLoginAt), 'd MMM yyyy HH:mm:ss') : ''}</td>
              <td>{userFriendlyNotificationSettings(obj.notificationSettings)}</td>
              <td>
                <a href="#" onClick={() => setUserForPasswordReset(obj)} title="reset password">
                  <LockReset />
                </a>
                <a href="#" onClick={() => setUserToEdit(obj)} title="edit user">
                  <Pencil />
                </a>
                {DeleteEnableActions(obj)}
              </td>
            </tr>
          ))}
          {!sortedUsers?.length ? (
            <tr>
              <td colSpan={6}>No results</td>
            </tr>
          ) : (
            []
          )}
        </tbody>
      </table>

      {ConfirmModals()}
      {userForPasswordReset ? (
        <ConfirmActionModal
          title="Reset Password?"
          message={`Are you sure you want to reset the password for ${userForPasswordReset.name}?`}
          confirmAction={() => handlePasswordReset(`${userForPasswordReset._id}`)}
          cancelAction={() => setUserForPasswordReset(null)}
        />
      ) : (
        []
      )}
      {userToEdit ? (
        <UserModal user={userToEdit} handleClose={() => setUserToEdit(null)} handleSave={handleUserNotificationsSave} />
      ) : (
        []
      )}
    </div>
  )
}

export default Users
