import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Dict } from '@memberapp/models'
import { Trash, TrashStrikeThru } from '../components/Icons'
import ConfirmActionModal from '../components/modals/ConfirmActionModal'

interface Props<T> {
  name?: string
  data: T[] | undefined
  queryKey: string
  filters: Dict<unknown>
  deleteFunc: (id: string) => Promise<Response>
  enableFunc: (id: string) => Promise<Response>
}

function useDeleteEnable<T extends { _id?: string; name?: string }>(props: Props<T>) {
  const { name, data, filters, queryKey, deleteFunc, enableFunc } = props
  const queryClient = useQueryClient()
  const [itemToDelete, setItemToDelete] = useState<T | null>(null)
  const [itemToEnable, setItemToEnable] = useState<T | null>(null)

  const {
    mutate: itemDelete,
    error: deleteError,
    isLoading: deleteLoading,
    reset: deleteReset,
  } = useMutation((id: string) => deleteFunc(id))

  const {
    mutate: itemEnable,
    error: enableError,
    isLoading: enableLoading,
    reset: enableReset,
  } = useMutation((id: string) => enableFunc(id))

  const isLoading = useMemo(() => {
    return deleteLoading || enableLoading
  }, [deleteLoading, enableLoading])

  const error = useMemo(() => {
    return deleteError || enableError
  }, [deleteError, enableError])

  const reset = useCallback(() => {
    if (queryClient) {
      deleteReset()
      enableReset()
      queryClient.resetQueries()
    }
  }, [deleteReset, queryClient, enableReset])

  const handleDelete = useCallback(
    (id: string) => {
      itemDelete(id, {
        onSuccess: () => {
          queryClient.setQueryData(
            queryKey,
            data?.filter((f: T) => f._id !== id)
          )
          queryClient.invalidateQueries()
          setItemToDelete(null)
        },
      })
    },
    [itemDelete, queryClient, setItemToDelete, queryKey, data]
  )

  const handleEnable = useCallback(
    (id: string) => {
      itemEnable(id, {
        onSuccess: () => {
          queryClient.setQueryData(
            queryKey,
            data?.filter((f: T) => f._id !== id)
          )
          queryClient.invalidateQueries()
          setItemToEnable(null)
        },
      })
    },
    [itemEnable, queryClient, queryKey, data, setItemToEnable]
  )

  const ConfirmModals = useCallback(() => {
    return (
      <>
        {itemToDelete ? (
          <ConfirmActionModal
            title={`Delete ${name || 'this item'}?`}
            message={`Are you sure you want to delete ${itemToDelete?.name || 'this item'}?`}
            confirmAction={() => handleDelete(`${itemToDelete._id}`)}
            cancelAction={() => setItemToDelete(null)}
          />
        ) : (
          []
        )}
        {itemToEnable ? (
          <ConfirmActionModal
            title={`Enable ${name}?`}
            message={`Make ${itemToEnable.name} active again?`}
            confirmAction={() => handleEnable(`${itemToEnable._id}`)}
            cancelAction={() => setItemToEnable(null)}
          />
        ) : (
          []
        )}
      </>
    )
  }, [itemToDelete, itemToEnable, name, handleDelete, setItemToDelete, handleEnable, setItemToEnable])

  const DeleteEnableActions = useCallback(
    (item: T) => {
      return filters?.includeDeleted ? (
        <a href="#" className="outline" onClick={() => setItemToEnable(item)}>
          <TrashStrikeThru />
        </a>
      ) : (
        <a href="#" className="outline" onClick={() => setItemToDelete(item)}>
          <Trash />
        </a>
      )
    },
    [filters, setItemToDelete, setItemToEnable]
  )

  return {
    ConfirmModals,
    DeleteEnableActions,
    isDeleteEnableLoading: isLoading,
    deleteEnableError: error,
    deleteEnableReset: reset,
  }
}

export default useDeleteEnable
