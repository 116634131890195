import React, { PropsWithChildren } from 'react'

import { AuthContainer } from '../containers/AuthContainer'

export type AuthorisedRouteType = {
  allowedRoles: string[]
}

const AuthorisedRoute: React.FC<PropsWithChildren<AuthorisedRouteType>> = ({ allowedRoles, children }) => {
  const { roles } = AuthContainer.useContainer()

  return roles.some((role: string) => allowedRoles.find((allowedRole) => allowedRole === role)) ? (
    <>{children}</>
  ) : (
    <hgroup>
      <h4>You are not authorised to view this page</h4>
    </hgroup>
  )
}

export default AuthorisedRoute
