import React, { useCallback } from 'react'
import { ShowModal } from '../../hooks/useModal'
import { NewsContainer } from '../../containers/NewsContainer'
import NewsForm from '../forms/NewsForm'

const NewsModal: React.FC = () => {
  const { modalType, saveDisabled, handleClose, handleSave } = NewsContainer.useContainer()

  const cancel = useCallback(() => handleClose && handleClose(), [handleClose])
  const save = useCallback(async () => {
    if (!handleSave) {
      return
    }
    await handleSave()
    handleClose && handleClose()
  }, [handleClose, handleSave])

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            {modalType === ShowModal.Edit ? 'Edit News' : 'Add News'}
            <a href="#close" aria-label="Close" className="close" onClick={cancel}></a>
          </h3>
        </header>
        <p>
          <NewsForm />
        </p>
        <footer>
          <a className="small" onClick={cancel}>
            Cancel
          </a>
          <button onClick={save} aria-disabled={saveDisabled} disabled={saveDisabled} className="small">
            Save
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default NewsModal
