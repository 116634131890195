import React, { Fragment, useCallback } from 'react'
import { format, startOfDay } from 'date-fns'
import { Bait, BaitOrder, BaitOrderDelivery, BaitOrderItem, TripTypeEnum } from '@memberapp/models'

import { EmptyLocation, EmptyUser, TripType } from '../../models'
import LabelWithError from '../LabelWithError'
import { TripContainer } from '../../containers/TripContainer'
import TimePicker from '../TimePicker'
import FavouritesDropdown from '../FavouritesDropdown'
import './TripForm.css'
import LocationFilter from '../LocationsFilter'
import { zonedFormat } from '@memberapp/models'

const describeBaitOrderDeliveryType = (baitOrder: BaitOrder | null | undefined): string => {
  if (!baitOrder?.deliveryType) {
    return BaitOrderDelivery.Nope
  }
  if (baitOrder.deliveryType !== BaitOrderDelivery.Scheduled) {
    return baitOrder.deliveryType
  }
  if (!baitOrder.collectionDate) {
    throw new Error('trip with scheduled bait order, no collection date')
  }
  if (!baitOrder.collectionLocation) {
    throw new Error('trip with scheduled bait order, no collection location')
  }
  return `Collect on ${zonedFormat(baitOrder.collectionDate, 'EEEE dd/LL/yyyy')}, from ${baitOrder.collectionLocation.name}`
}

const filterBait = (bait: BaitOrderItem[] = [], warehouse = ''): BaitOrderItem[] => {
  if (!warehouse) {
    return []
  }
  return bait.filter(b => !b.warehouse || b.warehouse === warehouse)
}

const TripForm: React.FC = () => {
  const {
    trip,
    handleAddBaitOrderChange,
    selectedUser,
    handleUserChange,
    handleChange,
    errors,
    userOptions,
    selectedLocation,
    handleLocationChange,
    editBaitOrder,
    removeBaitOrder,
    userFavouriteTrips,
    selectedFavTrip,
    handleSelectedFavTrip,
    setTrip,
  } = TripContainer.useContainer()

  const handleTimeChange = useCallback((name: string, value: string) => {
    setTrip((prevTrip) => ({ ...prevTrip, [name]: value }))
  }, [setTrip])

  return trip ? (
    <>
      <LabelWithError {...{ name: 'user', errors }}>Boat</LabelWithError>
      <select id="user" name="user" value={selectedUser} onChange={handleUserChange}>
        <option value={EmptyUser._id} disabled={true}>
          {EmptyUser.name}
        </option>
        {userOptions.map((m) => (
          <option key={m._id} value={m._id}>
            {m.boatName}
          </option>
        ))}
      </select>
      {trip.type !== TripTypeEnum.Favourite && trip.user ? (
        <>
          <label>Select Favourite?</label>
          <FavouritesDropdown
            list={userFavouriteTrips || []}
            onChange={(obj) => handleSelectedFavTrip(obj ? (obj as TripType) : null)}
            value={selectedFavTrip}
          />
        </>
      ) : (
        []
      )}
      <LabelWithError {...{ name: 'tripDate', errors }}>Trip Date</LabelWithError>
      <input
        type="date"
        name="tripDate"
        id="tripDate"
        value={format(trip.tripDate, 'yyyy-MM-dd')}
        required
        onChange={handleChange}
        min={format(startOfDay(new Date()), 'yyyy-MM-dd')}
      />
      <LabelWithError {...{ name: 'estimatedBaskets', errors }}>Estimated Baskets</LabelWithError>
      <input
        type="number"
        name="estimatedBaskets"
        id="estimatedBaskets"
        value={trip.estimatedBaskets}
        required
        min="1"
        step="1"
        onChange={handleChange}
      />
      <LabelWithError {...{ name: 'actualBaskets', errors }}>Actual Baskets</LabelWithError>
      <input
        type="number"
        name="actualBaskets"
        id="actualBaskets"
        value={!isNaN(parseInt(`${trip.actualBaskets}`)) ? `${trip.actualBaskets}` : ''}
        min="0"
        step="1"
        onChange={handleChange}
      />
      <LabelWithError {...{ name: 'offloadLocation', errors }}>Offload At</LabelWithError>
      <LocationFilter value={selectedLocation} onChange={handleLocationChange} placeholderText={EmptyLocation.name} />

      <LabelWithError {...{ name: 'offloadDate', errors }}>Offload Date</LabelWithError>
      <input
        type="date"
        name="offloadDate"
        id="offloadDate"
        value={format(trip.offloadDate, 'yyyy-MM-dd')}
        required
        onChange={handleChange}
        min={format(trip.tripDate, 'yyyy-MM-dd')}
      />
      <LabelWithError {...{ name: 'offloadTime', errors }}>Offload Time</LabelWithError>
      <TimePicker name="offloadTime" handleChange={handleTimeChange} initialValue={trip?.offloadTime} />
      <LabelWithError {...{ name: 'isFavourite', errors }}>Save as Favourite</LabelWithError>
      <input
        type="checkbox"
        name="isFavourite"
        id="isFavourite"
        checked={trip.type === TripTypeEnum.Favourite}
        onChange={handleChange}
      />
      {trip.type === TripTypeEnum.Favourite ? (
        <>
          <LabelWithError {...{ name: 'name', errors }}>Name</LabelWithError>
          <input type="text" id="name" name="name" value={trip?.name || ''} onChange={handleChange} />
        </>
      ) : (
        []
      )}
      {trip?.baitOrder?.items?.length ? (
        <>
          <label className="orderSummaryLabel">Bait Order</label>
          <span className="orderSummary">
            <a href="#" onClick={editBaitOrder}>
              Edit
            </a>
            <a href="#" onClick={removeBaitOrder}>
              Remove Order
            </a>
            <label className="fullSpan">{describeBaitOrderDeliveryType(trip.baitOrder)}</label>
            {filterBait(trip.baitOrder.items, trip.baitOrder.collectionLocation?.grading || trip.offloadLocation?.grading || '').map((m: BaitOrderItem) => (
              <Fragment key={`${m.name}.${m.description}`}>
                <label>
                  {m.name} {m.description}
                </label>
                <span>x {m.quantity}</span>
              </Fragment>
            ))}
            {
              !!trip.baitOrder.notes && (
                <>
                <br />
                <label className="fullSpan">Bait Order Notes:</label>
                <label className="fullSpan">{trip.baitOrder.notes}</label>
                </>
              )
            }
          </span>
        </>
      ) : (
        <>
          <LabelWithError {...{ name: 'hasBaitOrder', errors }}>Add Bait?</LabelWithError>
          <a href="#" onClick={() => handleAddBaitOrderChange(true)}>
            Add Bait Order
          </a>
        </>
      )}
    </>
  ) : null
}

export default TripForm

