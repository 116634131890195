import { startOfTomorrow } from 'date-fns'
import { useState } from 'react'
import DateFilter from '../../../components/DateFilter'
import { DownloadDocument } from '../../../components/Icons'
import LocationFilter from '../../../components/LocationsFilter'
import { LocationType } from '../../../models'

type Props = {
  downloadReport: (fn: () => Promise<Response>) => Promise<void>
  label: string
  getFn: (utcDate: Date, locationId: string) => Promise<Response>
}

const BaitOrdersDateLocation: React.FC<Props> = ({ downloadReport, label, getFn }) => {
  const [baitOrderDate, setBaitOrderDate] = useState<Date>(startOfTomorrow())
  const [baitOrderLocation, setBaitOrderLocation] = useState('')

  const downloadBaitOrderPerBoat = () => {
    downloadReport(() => getFn(baitOrderDate || startOfTomorrow(), baitOrderLocation))
  }

  const handleDateChange = (date: Date | null) => {
    setBaitOrderDate(date || startOfTomorrow())
  }

  return (
    <>
      <label>Date</label>
      <DateFilter value={baitOrderDate} onChange={handleDateChange} /> <label>Location</label>
      <LocationFilter
        value={baitOrderLocation}
        onChange={(u: LocationType | null) => setBaitOrderLocation(u?._id || '')}
      />
      <button onClick={downloadBaitOrderPerBoat}>
        <DownloadDocument />
        {label}
      </button>
      <span className="spacer" />
    </>
  )
}

export default BaitOrdersDateLocation
