import React, { SyntheticEvent, useCallback } from 'react'
import { Dict } from '@memberapp/models'

type Props = {
  setQueryParams: (query: Dict<unknown>) => void | React.Dispatch<React.SetStateAction<Dict<unknown>>>
  filters: Dict<unknown>
}

const FilterActiveDeleted: React.FC<Props> = ({ filters, setQueryParams }) => {
  const onChange = useCallback(
    (e: SyntheticEvent) => {
      if (!setQueryParams) {
        return
      }
      if (e?.target) {
        const { checked } = e.target as HTMLInputElement
        setQueryParams({ ...filters, includeDeleted: checked })
      }
    },
    [setQueryParams, filters]
  )
  return (
    <label htmlFor="includeDeleted">
      <input id="includeDeleted" type="checkbox" checked={filters.includeDeleted as boolean} onChange={onChange} />
      Show Deleted?
    </label>
  )
}

export default FilterActiveDeleted
