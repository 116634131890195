import { Dict } from '@memberapp/models'
import { BeachPriceType } from '../models'
import { buildQueryParams, Get, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'beachPrices'

const url = 'beachPrices'

const mapResponse = (prices: BeachPriceType[]): BeachPriceType[] => prices.map(mapBeachPrice)

const mapBeachPrice = (price: BeachPriceType): BeachPriceType =>
  ({
    ...price,
    date: new Date(price.date),
    lastNotificationSentAt: price.lastNotificationSentAt ? new Date(price.lastNotificationSentAt) : null,
  } as BeachPriceType)

export const getBeachPrices = async (query?: Dict<unknown>) =>
  Get<BeachPriceType[]>(buildQueryParams(url, query)).then(mapResponse)

export const getBeachPriceGrades = async () => Get<string[]>('settings/grades')

export const triggerManualBeachPriceNotification = async (id: string) =>
  untypedRequest(RequestMethod.POST, `notifications/beachPrices/${id}`)
