import React from 'react'
import './Modal.css'

interface Props {
  title: string
  message: string
  confirmAction: () => void
  cancelAction: () => void
  confirmText?: string
}

const ConfirmActionModal: React.FC<Props> = ({ title, message, confirmAction, cancelAction, confirmText = 'Ok' }) => {
  const onConfirm = async () => {
    try {
      confirmAction()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <dialog className="modal" open>
      <article>
        <header>
          <h3>
            {title}
            <a href="#close" aria-label="Close" className="close" onClick={cancelAction}></a>
          </h3>
        </header>
        <p>{message}</p>
        <footer>
          <a className="small" onClick={cancelAction}>
            Cancel
          </a>
          <button onClick={onConfirm} className="small">
            {confirmText}
          </button>
        </footer>
      </article>
    </dialog>
  )
}

export default ConfirmActionModal
