import { ComponentPropsWithoutRef } from 'react'
import { Trash } from './Icons'
import './ImagePreview.css'
type Props = {
  onRemove: () => void
  showRemove?: boolean
} & ComponentPropsWithoutRef<'img'>

function ImagePreview(props: Props) {
  const { onRemove, showRemove, ...rest } = props
  return (
    <span className="image-preview-wrapper">
      <span className="image-preview">
        <img {...rest} />
        {showRemove ? (
          <a href="#" onClick={onRemove}>
            <Trash />
          </a>
        ) : (
          []
        )}
      </span>
    </span>
  )
}

export default ImagePreview
