"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.predictedBasketsCalculation = exports.sortDeltas = exports.getBeachPriceDelta = void 0;
const date_fns_1 = require("date-fns");
const dateUtils_1 = require("./dateUtils");
const getBeachPriceDelta = (latestBeachPrice, previousBeachPrice) => {
    const delta = [];
    const prevDict = {};
    previousBeachPrice.prices.forEach((p) => prevDict[p.grade] = p);
    latestBeachPrice.prices.forEach((p) => {
        const gpd = { grade: p.grade, price: p.price, delta: 0 };
        const matching = prevDict[p.grade];
        if (matching) {
            gpd.delta = p.price - matching.price;
        }
        delta.push(gpd);
    });
    return delta;
};
exports.getBeachPriceDelta = getBeachPriceDelta;
const sortDeltas = (deltas, allowedGrades) => {
    const tmp = {};
    deltas.forEach(p => tmp[p.grade] = p);
    const sorted = [];
    allowedGrades.forEach(p => sorted.push(tmp[p]));
    return sorted;
};
exports.sortDeltas = sortDeltas;
const predictedBasketsCalculation = ({ percentageList, trip }) => {
    var _a, _b, _c, _d, _e;
    const zonedStartOfToday = (0, dateUtils_1.zonedStartOfDay)(new Date());
    const zonedStartOfOffloadDate = (0, dateUtils_1.zonedStartOfDay)(new Date(trip.offloadDate));
    const isPastDate = ((0, date_fns_1.isBefore)(zonedStartOfOffloadDate, zonedStartOfToday) && !(0, date_fns_1.isToday)(zonedStartOfOffloadDate));
    const predictedBasketData = { value: 0, displayValue: isPastDate ? 'N/A' : '-' };
    const pastUserTrips = ((_a = trip === null || trip === void 0 ? void 0 : trip.userTrips) === null || _a === void 0 ? void 0 : _a.filter((v) => (typeof v.actualBaskets === 'number') && (0, date_fns_1.isBefore)(new Date(v === null || v === void 0 ? void 0 : v.offloadDate), new Date(trip === null || trip === void 0 ? void 0 : trip.offloadDate)))) || [];
    if ((pastUserTrips.length < 3) && !isPastDate) {
        const predictedBasket = (_b = pastUserTrips[0]) === null || _b === void 0 ? void 0 : _b.actualBaskets;
        return Object.assign(Object.assign({}, predictedBasketData), { value: predictedBasket || 0, displayValue: predictedBasket ? predictedBasket.toString() : predictedBasketData.displayValue });
    }
    const firstPercent = percentageList[0] || 70;
    const secondPercent = percentageList[1] || 20;
    const thirdPercent = percentageList[2] || 10;
    if (firstPercent && secondPercent && thirdPercent) {
        const predictedBasket = (((+firstPercent / 100) * (((_c = pastUserTrips[0]) === null || _c === void 0 ? void 0 : _c.actualBaskets) || 0)) +
            ((+secondPercent / 100) * (((_d = pastUserTrips[1]) === null || _d === void 0 ? void 0 : _d.actualBaskets) || 0)) +
            ((+thirdPercent / 100) * (((_e = pastUserTrips[2]) === null || _e === void 0 ? void 0 : _e.actualBaskets) || 0)));
        return Object.assign(Object.assign({}, predictedBasketData), { value: Math.round(predictedBasket), displayValue: predictedBasket ? Math.round(predictedBasket).toString() : predictedBasketData.displayValue });
    }
    return predictedBasketData;
};
exports.predictedBasketsCalculation = predictedBasketsCalculation;
