import { Dict } from '@memberapp/models'
import { BaitOrderType, TripType } from '../models'
import { buildQueryParams, Get, Post, Put, RequestMethod, untypedRequest } from './httpSvc'

export const queryKey = 'trips'
export const favouritesQueryKey = 'trips/favourites'

const url = 'trips'

const mapTripResponse = (trips: TripType[]): TripType[] => trips.map(mapTrip)

const mapTrip = (trip: TripType): TripType => {
  const mapBaitOrder = (): BaitOrderType | null => {
    if (!trip.baitOrder) {
      return null
    }
    return {
      ...trip.baitOrder,
      collectionDate: trip.baitOrder.collectionDate ? new Date(trip.baitOrder.collectionDate) : null,
    } as BaitOrderType
  }
  return {
    ...trip,
    tripDate: new Date(trip.tripDate),
    offloadDate: new Date(trip.offloadDate),
    baitOrder: mapBaitOrder(),
  } as TripType
}

export const getTrips = async (query?: Dict<unknown>) => {
  if (query) {
    delete query.kgsPerBasket
  }
  return Get<TripType[]>(buildQueryParams(url, query)).then(mapTripResponse)
}

export const addTrip = async (obj: TripType) => Post<TripType>(url, obj).then(mapTrip)

export const editTrip = async (obj: TripType) => Put<TripType>(`${url}/${obj._id}`, obj).then(mapTrip)

export const deleteTrip = async (id: string) => untypedRequest(RequestMethod.DELETE, `${url}/${id}`)

export const enableTrip = async (id: string) => untypedRequest(RequestMethod.PUT, `${url}/${id}`, { deletedAt: null })

export const getFavourites = async (userId: string) =>
  userId ? Get<TripType[]>(`${url}/favourites/${userId}`).then(mapTripResponse) : Promise.resolve([])

export const getTripInfo = async (
  startUtcDate: Date,
  endUtcDate: Date,
  kilosPerBasket: number,
  locationId = '',
  tripType = '',
  boat = ''
) => {
  const query: Dict<unknown> = { startUtcDate, endUtcDate, kilosPerBasket }
  if (locationId) {
    query.locationId = locationId
  }
  if (tripType) {
    query.type = tripType
  }
  if (boat) {
    query.boat = boat
  }
  return untypedRequest(RequestMethod.GET, buildQueryParams(`${url}/reports/summary`, query))
}

export const updateActualBaskets = async (id: string, actualBaskets: number | null) =>
  Put<TripType>(`${url}/${id}/actualBaskets`, { actualBaskets } as TripType).then(mapTrip)
