import React, { SyntheticEvent, useCallback } from 'react'
import { addDays, addYears, startOfDay } from 'date-fns'
import { addMonths } from 'date-fns/esm'
import { Dict } from '@memberapp/models'

type Props = {
  setQueryParams: (query: Dict<unknown>) => void | React.Dispatch<React.SetStateAction<Dict<unknown>>>
  filters: Dict<unknown>
}

export const dfltState: Dict<unknown> = {
  start: addDays(startOfDay(new Date()), -7),
}

const dateRanges = [
  { value: addDays(startOfDay(new Date()), -1).getTime(), text: 'Yesterday' },
  { value: addDays(startOfDay(new Date()), -7).getTime(), text: 'Last Week' },
  { value: addMonths(startOfDay(new Date()), -1).getTime(), text: 'Last Month' },
  { value: addYears(startOfDay(new Date()), -1).getTime(), text: 'Last Year' },
]

const FilterBar: React.FC<Props> = ({ setQueryParams, filters }) => {
  const onChange = useCallback(
    (e: SyntheticEvent) => {
      if (!e?.target) {
        return
      }
      const { value, name } = e.target as HTMLSelectElement
      let val: Date | null = null
      switch (name) {
        case 'start':
          val = value ? new Date(+value) : (dfltState.date as Date)
          break
        default:
          val = null
          break
      }
      setQueryParams({ ...filters, [name]: val })
    },
    [setQueryParams, filters]
  )

  const clearQueryParams = useCallback(() => {
    setQueryParams(dfltState)
  }, [setQueryParams])

  return (
    <div className="filter-row">
      <label>Prices for</label>
      <select name="start" value={new Date(filters.start as Date).getTime()} onChange={onChange}>
        {dateRanges.map((d) => (
          <option key={d.value} value={d.value}>
            {d.text}
          </option>
        ))}
      </select>

      <a href="#" onClick={clearQueryParams}>
        Clear filter
      </a>
    </div>
  )
}

export default FilterBar
