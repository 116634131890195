import { Configuration, PopupRequest } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
    authority: 'https://login.microsoftonline.com/18967958-5c00-4d0d-864c-6e7447206097',
    redirectUri: `${process.env.REACT_APP_MSAL_REDIRECT_URI}`,
    postLogoutRedirectUri: `${process.env.REACT_APP_MSAL_POST_LOGOUT_REDIRECT_URI}`,
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read', 'Application.Read.All'],
}
