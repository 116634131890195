"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = exports.validateNewsItem = exports.rsvpValidHttp = exports.rsvpValidPhone = exports.rsvpValidEmail = exports.validateUser = exports.validateBaitOrder = exports.validateTrip = exports.validateUUID = void 0;
const types_1 = require("../types");
const date_fns_tz_1 = require("date-fns-tz");
const date_fns_1 = require("date-fns");
const constants_1 = require("../constants");
const uuidPatterns = {
    1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
    all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
};
function validateUUID(id, version = 'all') {
    const pattern = uuidPatterns[version];
    return pattern.test(id);
}
exports.validateUUID = validateUUID;
function validateTrip(trip, isCreateTrip, minDateOverride = null) {
    var _a;
    const errs = {};
    const minDate = minDateOverride ? minDateOverride : zonedStartOfDay(new Date());
    if (!trip) {
        errs['trip'] = 'trip is not defined';
        return errs;
    }
    if (!trip.user || !trip.user.boatName || !trip.user._id) {
        errs['user'] = 'invalid boat';
    }
    if (trip.type === types_1.TripTypeEnum.Favourite || trip.type === types_1.TripTypeEnum.Trip) {
        if (trip.tripDate < minDate) {
            errs['tripDate'] = 'invalid trip date';
        }
        if (trip.offloadDate < trip.tripDate || trip.offloadDate < minDate) {
            errs['offloadDate'] = 'invalid offload date';
        }
        if (!trip.offloadTime) {
            errs['offloadTime'] = 'invalid offload time';
        }
        if (!((_a = trip.offloadLocation) === null || _a === void 0 ? void 0 : _a.name)) {
            errs['offloadLocation'] = 'invalid offload location';
        }
        if (!trip.estimatedBaskets || trip.estimatedBaskets < 1) {
            errs['estimatedBaskets'] = 'invalid estimated baskets';
        }
        if (!isCreateTrip && trip.actualBaskets !== null && trip.actualBaskets < 0) {
            errs['actualBaskets'] = 'invalid actual baskets';
        }
    }
    if (trip.type === types_1.TripTypeEnum.Favourite && !trip.name) {
        errs['name'] = 'favourite trip requires a name';
    }
    return errs;
}
exports.validateTrip = validateTrip;
const validateBaitOrder = (x) => {
    var _a;
    const errs = {};
    const minDate = (0, date_fns_1.addDays)(zonedStartOfTomorrow(), -1);
    if (!x) {
        errs.baitOrder = 'bait order is null';
        return errs;
    }
    if (!x.user || !x.user.boatName || !x.user._id) {
        errs['user'] = 'invalid boat';
    }
    if (x.type !== types_1.BaitOrderType.Favourite) {
        if (!x.collectionDate) {
            errs.collectionDate = 'scheduled and trip bait orders need a collection date';
        }
        else if (x.collectionDate < minDate) {
            errs.collectionDate = 'scheduled and trip bait orders need a collection date';
        }
    }
    if (x.type === types_1.BaitOrderType.Favourite && !x.name) {
        errs.name = 'favourite bait orders require a name';
    }
    if (x.type === types_1.BaitOrderType.Scheduled && !x.collectionLocation) {
        errs.collectionLocation = 'scheduled bait orders require a valid collection location';
    }
    if (!((_a = x.items) === null || _a === void 0 ? void 0 : _a.length)) {
        errs.items = 'bait orders require at least one bait item';
    }
    else if (!x.items.every((s) => s.quantity > 0)) {
        errs.items = 'bait order items must have a quantity > 0';
    }
    return errs;
};
exports.validateBaitOrder = validateBaitOrder;
const validateUser = (u) => {
    const errs = {};
    if (!u) {
        errs.user = 'user is null';
        return errs;
    }
    if (!u.crmId) {
        errs.crmId = "ContactId is required";
    }
    else if (!validateUUID(u.crmId)) {
        errs.crmId = "ContactId is not a GUID";
    }
    if (!u.name) {
        errs.name = 'Full Name is required';
    }
    if (!u.email || !(0, exports.rsvpValidEmail)(u.email)) {
        errs.email = 'Email must be valid';
    }
    if (!u.memberCategory || !['Skipper', 'NonSkipper'].includes(u.memberCategory)) {
        errs.memberCategory = 'Member Category must be either Skipper or NonSkipper';
    }
    return errs;
};
exports.validateUser = validateUser;
const rsvpValidEmail = (rsvp) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(rsvp);
exports.rsvpValidEmail = rsvpValidEmail;
const rsvpValidPhone = (rsvp) => /^[0-9]{8,10}$/.test(rsvp.replace(' ', '').trim());
exports.rsvpValidPhone = rsvpValidPhone;
const rsvpValidHttp = (rsvp) => (rsvp.startsWith('http://') || rsvp.startsWith('https://')) && !rsvp.includes(' ');
exports.rsvpValidHttp = rsvpValidHttp;
const validateNewsItem = (obj) => {
    var _a, _b, _c;
    const errs = {};
    if (!obj) {
        errs['news'] = 'news item is not defined';
        return errs;
    }
    if (!obj.title) {
        errs.title = 'title is required';
    }
    if (!obj.type) {
        errs.type = 'type is required';
    }
    if (!obj.body) {
        errs.body = 'body is required';
    }
    if (!obj.scheduledToSendAt) {
        errs.scheduledToSendAt = 'scheduled send time is required';
    }
    else if (obj.scheduledToSendAt <= new Date()) {
        errs.scheduledToSendAt = 'scheduled send time should be in the future';
    }
    if (obj.type === types_1.NewsType.Event) {
        if (!obj.eventSummary) {
            errs['eventSummary'] = 'event summary is not defined';
        }
        else {
            if (!obj.eventSummary.time) {
                errs['eventSummary_time'] = 'event time is required';
            }
            const zonedTime = (0, date_fns_tz_1.zonedTimeToUtc)(obj.eventSummary.time, constants_1.defaultTz);
            const now = (0, date_fns_tz_1.zonedTimeToUtc)(new Date(), constants_1.defaultTz);
            console.log(zonedTime, now);
            if (zonedTime < now) {
                errs['eventSummary_time'] = 'event time should be in the future';
            }
            if (!((_b = (_a = obj.eventSummary) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.length)) {
                errs['eventSummary_location'] = 'at least one line of event location details is required';
            }
            if (!obj.eventSummary.rsvp) {
                errs['eventSummary_rsvp'] = 'event rsvp is required';
            }
            else {
                const validEmail = (0, exports.rsvpValidEmail)(obj.eventSummary.rsvp);
                const validPhone = (0, exports.rsvpValidPhone)(obj.eventSummary.rsvp);
                const validHttp = (0, exports.rsvpValidHttp)(obj.eventSummary.rsvp);
                if (!validEmail && !validPhone && !validHttp) {
                    errs['eventSummary_rsvp'] = 'event rsvp is not an email address, phone number or website url';
                }
            }
        }
    }
    if (obj.type === types_1.NewsType.Message) {
        if (!((_c = obj.messageSummary) === null || _c === void 0 ? void 0 : _c.length)) {
            errs['messageSummary'] = 'at least one message summary paragraph is required';
        }
    }
    return errs;
};
exports.validateNewsItem = validateNewsItem;
const zonedStartOfDay = (inputDate, tz = constants_1.defaultTz) => {
    const rawDate = new Date(inputDate);
    const zonedDate = (0, date_fns_tz_1.utcToZonedTime)(rawDate, tz);
    const zonedStart = (0, date_fns_1.startOfDay)(zonedDate);
    return (0, date_fns_tz_1.zonedTimeToUtc)(zonedStart, tz);
};
const zonedStartOfTomorrow = (tz = constants_1.defaultTz) => {
    const start = zonedStartOfDay(new Date(), tz);
    return (0, date_fns_1.addDays)(start, 1);
};
const zonedFormat = (date, formatString, tz = constants_1.defaultTz) => {
    return (0, date_fns_tz_1.formatInTimeZone)(date, tz, formatString);
};
function validatePassword(password) {
    const errors = [];
    if (password.length < 8) {
        errors.push("Password must be at least 8 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
        errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
        errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/[0-9]/.test(password)) {
        errors.push("Password must contain at least one digit.");
    }
    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
        errors.push("Password must contain at least one special character.");
    }
    if (/\s/.test(password)) {
        errors.push("Password cannot contain empty spaces.");
    }
    return errors;
}
exports.validatePassword = validatePassword;
