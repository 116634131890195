import { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { MemberCategory, TripTypeEnum, Id, validateTrip, User, Location, applyFavouriteTripToTrip, filterBaitOrderItemsForWarehouse } from '@memberapp/models'
import { startOfDay } from 'date-fns'
import { UseMutateFunction, useQuery, useQueryClient } from 'react-query'
import { createContainer } from 'unstated-next'
import { ShowModal } from '../hooks/useModal'
import {
  BaitOrderType,
  EmptyBaitOrder,
  EmptyTrip,
  LocationType,
  INITIAL_OFFLOAD_TIME,
  TripType,
  UserType,
  EmptyUser,
} from '../models'
import { queryKey as locationQueryKey, getLocations } from '../services/locationsSvc'
import { queryKey as userQueryKey, getUsers } from '../services/userSvc'
import { getFavourites, queryKey } from '../services/tripSvc'
import { getBait, queryKey as baitQueryKey } from '../services/baitSvc'

type InitialState = {
  obj: TripType | null
  modalType: ShowModal
  addMutate: UseMutateFunction<TripType, unknown, TripType, unknown> | (() => void)
  editMutate: UseMutateFunction<TripType, unknown, TripType, unknown> | (() => void)
}

const state: InitialState = {
  obj: EmptyTrip,
  modalType: ShowModal.None,
  addMutate: () => { },
  editMutate: () => { },
}

function useTrip(initialState: InitialState = state) {
  const queryClient = useQueryClient()
  const { addMutate, editMutate } = initialState
  const [trip, setTrip] = useState<TripType>(initialState?.obj as TripType)
  const [modalType, setModalType] = useState<ShowModal>(initialState?.modalType || ShowModal.None)
  const [hasBaitOrder, setHasBaitOrder] = useState<boolean>(!!trip?.baitOrder)
  const [showBaitOrder, setShowBaitOrder] = useState<boolean>(false)

  const { data: users } = useQuery(userQueryKey, () => getUsers({ includeDeleted: false }))
  const { data: deletedUsers } = useQuery('userQueryKey/deleted', () => getUsers({ includeDeleted: true }))
  const { data: locationOptions } = useQuery(locationQueryKey, () => getLocations())
  const { data: bait } = useQuery(baitQueryKey, () => getBait())

  const userOptions = useMemo(() => {
    let tmpUsers: UserType[] = []
    if (users?.length) {
      tmpUsers = [...users]
    }
    if (modalType === ShowModal.Edit && deletedUsers?.length) {
      tmpUsers = [...tmpUsers, ...deletedUsers]
    }
    return tmpUsers.filter((f: UserType) => f.memberCategory === MemberCategory.Skipper)
  }, [users, deletedUsers, modalType])

  const userId = (trip?.user as UserType)?._id || ''
  const { data: userFavouriteTrips, isLoading: isFavTripsLoading } = useQuery(['fav/trip', userId], () =>
    getFavourites(userId)
  )

  const minDateOverride = (!modalType || modalType !== ShowModal.Add) ? null : startOfDay(new Date())
  const errors = validateTrip(trip, modalType === ShowModal.Add, minDateOverride)

  const handleAddBaitOrderChange = (checked: boolean) => {
    if (!checked) {
      setTrip({ ...trip, baitOrder: null })
      setHasBaitOrder(false)
      setShowBaitOrder(false)
      return
    }
    const baitOrder = EmptyBaitOrder
    baitOrder.user = trip.user
    baitOrder.collectionDate = trip.tripDate
    baitOrder.collectionLocation = trip.offloadLocation
    setTrip({ ...trip, baitOrder })
    setHasBaitOrder(true)
    setShowBaitOrder(true)
  }

  const { landLocations, boatLocations } = useMemo(() => {
    return {
      landLocations: (locationOptions || [])
        .filter((f) => !f.isCarrierBoat)
        .sort((a: LocationType, b: LocationType) => a.name.localeCompare(b.name)),
      boatLocations: (locationOptions || [])
        .filter((f) => f.isCarrierBoat)
        .sort((a: LocationType, b: LocationType) => a.name.localeCompare(b.name)),
    }
  }, [locationOptions])

  const handleSelect = (evt: SyntheticEvent, name: string, list: Id[]) => {
    if (evt?.target) {
      const { value } = evt.target as HTMLInputElement
      const obj = list.find((f) => f._id === value)
      if (obj) {
        if (name === 'user') {
          setTrip({
            ...trip,
            [name]: obj as User & Id,
            estimatedBaskets: 0,
            offloadLocation: null,
            offloadDate: trip.tripDate,
            offloadTime: INITIAL_OFFLOAD_TIME,
            baitOrder: null,
            type: TripTypeEnum.Trip,
          })
        } else {
          setTrip({ ...trip, [name]: obj })
        }
      }
    }
  }

  const handleUserChange = (evt: SyntheticEvent) => {
    handleSelect(evt, 'user', userOptions)
  }

  const handleLocationChange = (locEvt: Location | null) => {
    if (locEvt) {
      const evt = { target: { value: (locEvt as LocationType)._id || '' } as HTMLInputElement }
      handleSelect(evt as any as SyntheticEvent, 'offloadLocation', locationOptions || [])
      return
    }
    const evt = { target: { value: '' } as HTMLInputElement }
    handleSelect(evt as any as SyntheticEvent, 'offloadLocation', locationOptions || [])
  }

  const handleChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target || !setTrip) {
        return
      }
      const { name, value, checked, type: inputType } = event?.target as HTMLInputElement
      let newVal: unknown = null
      switch (name) {
        case 'isFavourite': {
          const newObj: TripType = { ...trip, type: checked ? TripTypeEnum.Favourite : TripTypeEnum.Trip }
          setTrip(newObj)
          break
        }
        case 'actualBaskets': {
          newVal = value === '' ? null : +value
          const newObj: TripType = { ...trip, [name]: newVal as number | null }
          setTrip(newObj)
          break
        }
        default: {
          switch (inputType) {
            case 'checkbox':
              newVal = checked
              break
            case 'date':
              newVal = startOfDay(new Date(value))
              break
            case 'number':
            case 'numeric':
              newVal = +value
              break
            default:
              newVal = value
          }
          const newObj: TripType = { ...trip, [name]: newVal }
          setTrip(newObj)
        }
      }
    },
    [trip, setTrip]
  )

  const { user, offloadLocation, baitOrder } = useMemo<{
    user: UserType | null
    offloadLocation: LocationType | null
    baitOrder: BaitOrderType | null
  }>(() => {
    return {
      user: trip?.user || null,
      offloadLocation: trip?.offloadLocation || null,
      baitOrder: trip?.baitOrder || null,
    }
  }, [trip])

  const selectedUser = user ? (user as UserType)._id || '' : ''
  const selectedLocation = offloadLocation ? (offloadLocation as LocationType)._id || '' : ''

  const removeBaitOrder = useCallback(() => {
    if (setTrip) {
      setTrip({ ...trip, baitOrder: null })
    }
  }, [setTrip, trip])

  const editBaitOrder = useCallback(() => {
    if (setShowBaitOrder) {
      setShowBaitOrder(true)
      if (trip?.baitOrder) {
        setTrip({ ...trip, baitOrder: { ...trip.baitOrder, user: trip.user } })
      }
    }
  }, [setShowBaitOrder, setTrip, trip])

  const [selectedFavTrip, setSelectedFavTrip] = useState<TripType | null>(null)

  const handleSelectedFavTrip = (favTrip: TripType | null) => {
    setSelectedFavTrip(favTrip)
    if (favTrip) {
      const newTrip = applyFavouriteTripToTrip(trip, favTrip)
      if (newTrip.baitOrder) {
        newTrip.baitOrder.items = filterBaitOrderItemsForWarehouse(
          newTrip.baitOrder.items,
          bait,
          newTrip.baitOrder.collectionLocation?.grading || ''
        )
      }
      setTrip(newTrip as TripType)
    }
  }

  const tripSaveDisabled = !!Object.keys(errors)?.length

  const handleSave = async () => {
    if (!trip) {
      return
    }
    const mutateFn = modalType === ShowModal.Add ? addMutate : editMutate
    if (!mutateFn) {
      return
    }
    return mutateFn(trip, {
      onSuccess: (data: TripType) => {
        queryClient.setQueryData([queryKey, { id: data._id }], data)
        queryClient.invalidateQueries()
      },
      onError: (error: unknown) => {
        console.error('trip mutation onError', error)
      },
    })
  }

  return {
    trip,
    setTrip,
    hasBaitOrder,
    handleAddBaitOrderChange,
    showBaitOrder,
    setShowBaitOrder,
    handleChange,
    handleUserChange,
    handleLocationChange,
    landLocations,
    boatLocations,
    errors,
    selectedUser,
    selectedLocation,
    baitOrder,
    modalType,
    setModalType,
    tripSaveDisabled,
    userOptions,
    removeBaitOrder,
    editBaitOrder,
    handleSave,
    userFavouriteTrips,
    isFavTripsLoading,
    selectedFavTrip,
    handleSelectedFavTrip,
  }
}

export const TripContainer = createContainer(useTrip)
