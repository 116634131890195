import { NotificationTopics } from '@memberapp/models'
import { useState } from 'react'
import BasicSelect from '../../../components/BasicSelect'
import { DownloadDocument } from '../../../components/Icons'
import { usersNotSubscribed } from '../../../services/userSvc'

type Props = {
  downloadReport: (fn: () => Promise<Response>) => Promise<void>
}

const UsersNotSubscribed: React.FC<Props> = ({ downloadReport }) => {
  const [notSubscribed, setNotSubscribed] = useState<string>('')

  const downloadUsersNotSubscribed = async () => {
    downloadReport(() => usersNotSubscribed(notSubscribed))
  }

  return (
    <>
      <label>Notifications Off</label>
      <BasicSelect value={notSubscribed} onChange={setNotSubscribed}>
        <option value="">All notifications off</option>
        <option value={NotificationTopics.BeachPrice}>Beach Prices off</option>
        <option value={NotificationTopics.NewsEvent}>Events off</option>
        <option value={NotificationTopics.NewsMessage}>Messages off</option>
      </BasicSelect>
      <button onClick={downloadUsersNotSubscribed}>
        <DownloadDocument />
        Not subscribed
      </button>
      <span style={{ gridColumn: '1/-1' }}>users who have disabled push notifications at device level not tracked</span>
      <span className="spacer"></span>
    </>
  )
}

export default UsersNotSubscribed
