import React, { ComponentPropsWithoutRef } from 'react'

type Props = {
  errors: { [id: string]: unknown }
  name: string
  className?: string
} & ComponentPropsWithoutRef<'label'>

function LabelWithError(props: Props) {
  const { errors, name, children, className, ...rest } = props
  return (
    <label htmlFor={name} className={` ${className || ''} ${errors?.[name] ? 'error' : ''}`} {...rest}>
      {children}
    </label>
  )
}

export default LabelWithError
