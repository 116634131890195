import { SyntheticEvent } from 'react'

type ListItem = {
  _id?: string
  name: string
}

type Props = {
  value: ListItem | null
  onChange: (obj: ListItem | null) => void
  list: ListItem[]
}

const FavouritesDropdown: React.FC<Props> = ({ value, onChange, list }) => {
  const handleChange = (event: SyntheticEvent) => {
    if (!event.target) {
      return
    }
    const { value } = event.target as HTMLSelectElement
    if (value) {
      const found = (list || []).find((f) => f._id === value)
      onChange(found || null)
    }
  }
  return (
    <select value={value?._id || ''} onChange={handleChange}>
      <option value="">{list?.length > 0 ? 'Select...' : 'Nothing available'}</option>
      {list?.map((m: ListItem) => (
        <option key={m._id} value={m._id}>
          {m.name}
        </option>
      ))}
    </select>
  )
}

export default FavouritesDropdown
