import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Trash } from './Icons'
import './MultiStringInput.css'

interface Props {
  items: string[]
  onChange: (items: string[]) => void
  name: string
}

const MultiStringInput: React.FC<Props> = ({ items, onChange, name }) => {
  const [item, setItem] = useState<string>('')

  const onTextChange = useCallback(
    (e: SyntheticEvent) => {
      if (!setItem || !e?.target) {
        return
      }
      const { value } = e.target as HTMLInputElement
      setItem(value)
    },
    [setItem]
  )

  const addItem = useCallback(() => {
    if (!onChange || !setItem) {
      return
    }
    const tmp = [...items]
    tmp.push(item)
    onChange(tmp)
    setItem('')
  }, [items, item, setItem, onChange])

  const removeItem = useCallback(
    (item: string) => {
      if (!onChange) {
        return
      }
      const tmp = (items || []).filter((f: string) => f !== item) || []
      onChange(tmp)
    },
    [onChange, items]
  )
  return (
    <span className="multi-string-input">
      <input type="text" id={name} name={name} value={item} onChange={onTextChange} />
      <button onClick={addItem} disabled={!item?.length}>
        +
      </button>
      {items?.length
        ? items.map((m: string) => (
            <React.Fragment key={m}>
              <p>{m}</p>
              <a href="#" onClick={() => removeItem(m)}>
                <Trash width={'1.5em'} height={'1.5em'} />
              </a>
            </React.Fragment>
          ))
        : []}
    </span>
  )
}

export default MultiStringInput
