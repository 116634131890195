import React, { SyntheticEvent, useCallback } from 'react'
import { format, startOfDay } from 'date-fns'

type DateRangeFilterProps = {
  start: Date | null
  end: Date | null
  onStartChange: (value: Date | null) => void | React.Dispatch<React.SetStateAction<Date>>
  onEndChange: (value: Date | null) => void | React.Dispatch<React.SetStateAction<Date>>
  noWrapper: boolean
}

const Wrapper: React.FC<React.PropsWithChildren<{ noWrapper: boolean }>> = ({ noWrapper, children }) => {
  return noWrapper ? <>{children}</> : <div>{children}</div>
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  start,
  end,
  onStartChange,
  onEndChange,
  noWrapper = false,
}) => {
  const handleStartChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target) {
        return
      }
      const { value } = event?.target as HTMLInputElement
      const newVal = value ? startOfDay(new Date(value)) : null
      onStartChange(newVal)
    },
    [onStartChange]
  )

  const handleEndChange = useCallback(
    (event: SyntheticEvent) => {
      if (!event || !event.target) {
        return
      }
      const { value } = event?.target as HTMLInputElement
      const newVal = value ? startOfDay(new Date(value)) : null
      onEndChange(newVal)
    },
    [onEndChange]
  )

  return (
    <Wrapper noWrapper={noWrapper}>
      <>
        <label>Start</label>
        <input
          type="date"
          value={start ? format(start, 'yyyy-MM-dd') : ''}
          max={end ? format(end, 'yyyy-MM-dd') : ''}
          onChange={handleStartChange}
        />
        <label>End</label>
        <input
          type="date"
          value={end ? format(end, 'yyyy-MM-dd') : ''}
          min={start ? format(start, 'yyyy-MM-dd') : ''}
          onChange={handleEndChange}
        />
      </>
    </Wrapper>
  )
}

export default DateRangeFilter
